import fetch from 'src/helpers/fetch'
import { REQ_URI } from 'src/constants/index'

interface IRedhareNoticeResponse {
	code: number
	message: string
	data?: any
}
export const getRedhareNotice = async (): Promise<IRedhareNoticeResponse> =>
	fetch({ method: 'GET', resource: `${REQ_URI.GET_NOTICE}` })
