import style from './index.scss'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import {
  Space,
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  Popconfirm,
  Tooltip,
  Radio,
  Checkbox,
} from 'antd'
import {
  DownloadOutlined,
  LaptopOutlined,
  MobileOutlined,
  PlusOutlined,
  DesktopOutlined,
} from '@ant-design/icons'
import { ColumnsType } from 'antd/es/table'
import {
  addSystemMaterial,
  deleteSystemMaterial,
  getMaterialVersions,
  getSystemMaterials,
  IMaterial,
  ISystemMaterial,
  PageType,
  updateSystemMaterial,
} from 'src/api'
import { MaterialTypeIcon } from 'src/components/ProjectTypeIcon'

const SystemMaterial: FC = () => {
  const [materials, setMaterials] = useState<IMaterial[]>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [currentMaterialVersions, setCurrentMaterialVersions] = useState<string[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [isVersionsLoading, setIsVersionsLoading] = useState(false)
  const [form] = Form.useForm<ISystemMaterial>()
  const [currentMaterial, setCurrentMaterial] = useState<ISystemMaterial>(null)

  const handleAdd = useCallback(() => {
    setIsEditing(false)
    setModalVisible(true)
    form.resetFields()
  }, [form])

  const loadSystemMaterials = useCallback(async () => {
    const materials = await getSystemMaterials()
    setMaterials(materials)
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields()
      const model = form.getFieldsValue()
      if (isEditing) {
        await updateSystemMaterial({
          ...currentMaterial,
          ...model,
        })
      } else {
        await addSystemMaterial(model)
      }
      setModalVisible(false)
      await loadSystemMaterials()
    } catch (ex) {}
  }, [form, isEditing, currentMaterial, loadSystemMaterials])

  const getVersions = useCallback(async () => {
    setIsVersionsLoading(true)
    const materialName = form.getFieldValue('name')
    if (materialName) {
      const versions = await getMaterialVersions(materialName)
      if (Array.isArray(versions)) {
        setCurrentMaterialVersions(versions.reverse())
      } else {
        setCurrentMaterialVersions([versions])
      }
    }
    setIsVersionsLoading(false)
  }, [form])

  const handleChangeVersion = useCallback(
    (material: ISystemMaterial) => {
      setIsEditing(true)
      setModalVisible(true)
      setCurrentMaterial(material)
      form.setFieldsValue(material)
      getVersions()
    },
    [getVersions, form],
  )

  const handleDelete = useCallback(
    async (material: ISystemMaterial) => {
      await deleteSystemMaterial(material)
      await loadSystemMaterials()
    },
    [loadSystemMaterials],
  )

  const columns = useMemo<ColumnsType<ISystemMaterial>>(
    () => [
      {
        title: '物料名称',
        dataIndex: 'name',
      },
      {
        title: '物料版本',
        dataIndex: 'version',
      },
      {
        title: '物料类型',
        dataIndex: 'type',
        render: (materialType: PageType) => <MaterialTypeIcon type={materialType} />,
      },
      {
        title: '操作',
        render: (value, record) => (
          <Space>
            <Button type="link" onClick={() => handleChangeVersion(record)}>
              更新版本
            </Button>
            <Popconfirm title="Are you sure to delete?" onConfirm={() => handleDelete(record)}>
              <Button type="link">删除</Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [handleChangeVersion, handleDelete],
  )

  useEffect(() => {
    loadSystemMaterials()
  }, [loadSystemMaterials])

  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <Button onClick={handleAdd} type="primary" icon={<PlusOutlined />}>
          添加物料
        </Button>
      </div>
      <Modal
        visible={modalVisible}
        title="添加物料"
        onCancel={() => setModalVisible(false)}
        onOk={handleSubmit}
        okText="确定"
        cancelText="取消"
      >
        <Form form={form} labelCol={{ span: 8 }}>
          <Form.Item label="物料名称" name="name" rules={[{ required: true, message: '请输入' }]}>
            <Input
              disabled={isEditing}
              suffix={
                <Tooltip title="获取版本">
                  <DownloadOutlined onClick={getVersions} />
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item
            label="物料版本"
            name="version"
            rules={[{ required: true, message: '请选择物料版本' }]}
          >
            <Select loading={isVersionsLoading} disabled={isVersionsLoading}>
              {currentMaterialVersions.map((version) => (
                <Select.Option key={version} value={version}>
                  {version}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="物料类型"
            name="type"
            initialValue={PageType.PC}
            rules={[{ required: true, message: '请选择物料版本' }]}
          >
            <Radio.Group optionType="button" buttonStyle="solid" disabled={isEditing}>
              <Radio.Button value={PageType.PC}>
                PC物料 <LaptopOutlined />
              </Radio.Button>
              <Radio.Button value={PageType.H5}>
                H5物料 <MobileOutlined />
              </Radio.Button>
              <Radio.Button value={PageType.COMMON}>
                通用物料 <DesktopOutlined />
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Table dataSource={materials} columns={columns} pagination={false} />
    </div>
  )
}
export default SystemMaterial
