import { createContext, FC, useContext } from 'react'
import useQuery from 'src/hooks/useQuery'
import Portal from 'src/pages/projectDetail/components/Portal'
import Detail from './components/Detail'
import PageList from './components/PageList'
import { BLOCK_ID_MAP } from './constants/anchor'
import { getProject, IProject } from 'src/api'
import styles from './style.scss'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'
import { AppInterface } from './components/interfaces'

export interface IProjectContext {
  id: string
  project: IProject
  loading: boolean
  refresh: () => any
}
export const ProjectContext = createContext<Partial<IProjectContext>>({})
export const useProjectContext = () => useContext(ProjectContext)

const ProjectDetail: FC = () => {
  const query = useQuery()
  const { data, isFetching, refetch } = useErrorQuery(['getProject', query.id], () =>
    getProject(query.id),
  )

  return (
    <ProjectContext.Provider
      value={{
        id: query.id,
        project: data,
        loading: isFetching,
        refresh: refetch,
      }}
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.appAndPortalArea}>
            <div id={BLOCK_ID_MAP.detail.id} className={`${styles.detail} ${styles.detailSize}`}>
              <Detail />
            </div>
            <div id={BLOCK_ID_MAP.portal.id} className={`${styles.portal} ${styles.portalSize}`}>
              <Portal />
            </div>
          </div>
          <div
            id={BLOCK_ID_MAP.pageList.id}
            className={`${styles.pageList} ${styles.pageListSize}`}
          >
            <PageList />
          </div>
        </div>
      </div>
    </ProjectContext.Provider>
  )
}

export default ProjectDetail
