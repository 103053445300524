import { FC } from 'react'
import { IGetDataCenterDataResponse } from 'src/api'
import { AVG_PV_LOGO, TOTAL_PROJECTS_LOGO, TOTAL_PUBLISH_LOGO, TOTAL_USERS_LOGO } from 'src/constants'
import styles from '../style.scss'

interface IOverviewProps {
  datasource: IGetDataCenterDataResponse['data']['overView']
  projectDays: number
}

const Overview: FC<IOverviewProps> = (props) => {
  const { datasource = { totalUsers: null, projectsCount: null, publishCount: null, avgPV: null }, projectDays } = props
  const { totalUsers, projectsCount, publishCount, avgPV } = datasource
  return (
    <div className={styles.overview}>
      <span className={styles.overviewTitle}>概览</span>
      <div className={styles.overviewArea}>
        <div className={`${styles.totalUsersArea} ${styles.itemSize} ${styles.totalUsersTextColor}`}>
          <img src={TOTAL_USERS_LOGO} alt='' />
          <span>{`用户：${totalUsers ? totalUsers : '> 500'}`}</span>
        </div>
        <div className={`${styles.totalProjectsArea} ${styles.itemSize} ${styles.totalProjectsTextColor}`}>
          <img src={TOTAL_PROJECTS_LOGO} alt='' />
          <span>{`活跃项目：${projectsCount}`}</span>
        </div>
        <div className={`${styles.totalPublishArea} ${styles.itemSize} ${styles.totalPublishTextColor}`}>
          <img src={TOTAL_PUBLISH_LOGO} alt='' />
          <span>{`近${projectDays}日累计发布：${publishCount}`}</span>
        </div>
        <div className={`${styles.avgPVArea} ${styles.itemSize} ${styles.avgPVTextColor} ${styles.lastItem}`}>
          <img src={AVG_PV_LOGO} alt='' />
          <span>{`日均PV：${avgPV}`}</span>
        </div>
      </div>
    </div>
  )
}

export default Overview
