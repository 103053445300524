import { downloadFile } from 'src/helpers/download'
import { getApiFullUrl } from 'src/helpers/fetch'

export function downloadPageZip(id: string, projectId: string) {
  const url = getApiFullUrl(`download/page/${id}?projectId=${projectId}`)
  downloadFile(url)
}

export function downloadPortalZip(id: string, projectId: string) {
  const url = getApiFullUrl(`download/portal/${id}?projectId=${projectId}`)
  downloadFile(url)
}

export function downloadAppZip(id: string) {
  const url = getApiFullUrl(`download/app/${id}`)
  downloadFile(url)
}
