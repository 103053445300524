import fetch from 'src/helpers/fetch'
import { IPagingParams, IPagingRes } from 'src/types/custom'
import { REQUEST_STATUS } from 'src/constants/index'
import { gotoLogin } from 'src/helpers/login'
import { IMaterial } from './material'
/**
 * Project CRUD
 */

export enum PageType {
  PC = 1,
  H5 = 2,
  COMMON = 3,
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}

export interface IAppDataSourceInterface {
  id: string
  uri: string
  method: HttpMethod
  willFetch?: string
  willFetchEnabled?: boolean
  dataHandler?: string
  dataHandlerEnabled?: boolean
  errorHandler?: string
  errorHandlerEnabled?: boolean
}

export interface IAppDataSource {
  id: string
  name: string
  description?: string
  interfaces: IAppDataSourceInterface[]
  willFetch?: string
  willFetchEnabled?: boolean
  dataHandler?: string
  dataHandlerEnabled?: boolean
  errorHandler?: string
  errorHandlerEnabled?: boolean
}

export interface IAppDep {
  package: string
  urls: string[]
  version?: string
  library?: string
  docUrl?: string
  description?: string
}

export interface IProject {
  id: string
  name: string
  picture: string
  description: string
  type: PageType
  dataSource?: {
    list: IAppDataSource[]
    version: string
  }
  materials: IMaterial[]
  deps: IAppDep[]
  createdAt: number
  createdBy: string
  updatedAt: number
  updatedBy: string
  isPublic: number
}
export type IProjectInfo = Pick<
  IProject,
  'name' | 'description' | 'type' | 'createdBy' | 'updatedBy' | 'picture'
>
/*
 * TODO(BE): No data when current is not equal to 1
 * TODO(BE): Sort type
 */
export const getProjectList = async (data: IPagingParams) => {
  const res = await fetch<IPagingRes<IProject>>({
    method: 'GET',
    resource: 'project/list',
    params: data,
  })
  if (res.code === REQUEST_STATUS.UNAUTHORED) {
    gotoLogin()
  }

  // 将is_public = 1的项目排在前面
  res?.records?.sort((a, b) => {
    // 如果 a 的 isPublic 为 1，排在前面
    if (a?.isPublic === 1) {
      return -1
    }
    // 如果 b 的 isPublic 为 1，排在前面
    if (b?.isPublic === 1) {
      return 1
    }
    // 否则，保持原来的顺序
    return 0
  })
  return res
}

export const addProject = async (data: IProjectInfo) =>
  fetch({
    method: 'POST',
    resource: 'project',
    payload: data,
  })

export const getProject = async (id: string) =>
  fetch<IProject>({ method: 'GET', resource: `project/${id}` })

export const patchProject = async (data: IProject) =>
  fetch({
    method: 'PUT',
    resource: `project/${data.id}`,
    payload: data,
  })

export const delProject = async (id: string) =>
  fetch({ method: 'DELETE', resource: `project/${id}` })
