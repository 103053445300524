import fetch from 'src/helpers/fetch'
import { REQ_URI } from 'src/constants/index'

interface IGetProjectUsersReturn {
	code: number
	data?: any
	message: string
}

export const getProjectUsersList = async (
	id: string
): Promise<IGetProjectUsersReturn> =>
	fetch({ method: 'GET', resource: `${REQ_URI.GET_PROJECT_USERS}/${id}` })
