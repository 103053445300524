export const ROUTES = {
  APP: '/app',
  APP_DETAIL: '/app-detail',
  WORKBENCH: '/workbench',
  PREVIEW: '/preview',
  PREVIEW_PORTAL: '/preview-portal',
  TEMPLATE: '/template',
  MATERIAL: '/material',
  COURSE: '/course',
  DATA_CENTER: '/data-center',
}
