import fetch from 'src/helpers/fetch'
import { IPublicTypeProjectSchema } from '@redhare/lowcode-types'
import { IPagingParams, IPagingRes } from 'src/types/custom'

/**
 * Schema Management
 */
export interface IPackage {
	[props: string]: any
}

export interface ISchema {
	id: string
	projectId?: string
	ownerId?: string
	pageId?: string
	portalId?: string
	schema: IPublicTypeProjectSchema
	packages: IPackage[]
	createdAt?: number
	createdBy?: string
}

export type ISchemaModel = Omit<ISchema, 'id' | 'createdAt' | 'createdBy'>

export const addPageSchema = async (data: ISchemaModel) =>
	fetch<ISchema | undefined>({
		method: 'POST',
		resource: 'page-schema/page',
		payload: data,
	})

export const addPortalSchema = async (data: ISchemaModel) =>
	fetch<ISchema | undefined>({
		method: 'POST',
		resource: 'page-schema/portal',
		payload: data,
	})

export const getPageSchemaList = async (
	data: IPagingParams & { pageId: string }
) =>
	fetch<IPagingRes<ISchema>>({
		method: 'GET',
		resource: 'page-schema/list',
		params: data,
	})

export const getSchemaByPageId = async (pageId: string, projectId: string) =>
	fetch<ISchema | undefined>({
		method: 'GET',
		resource: `page-schema/page/${pageId}/${projectId}`,
	})

export const getSchemaByPortalId = async (portalId: string) =>
	fetch<ISchema | undefined>({
		method: 'GET',
		resource: `page-schema/portal/${portalId}`,
	})

export const getSchemaById = async (schemaId: string) =>
	fetch<ISchema | undefined>({
		method: 'GET',
		resource: `page-schema/${schemaId}`,
	})
