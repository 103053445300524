/**
 * the space fetch method has bug when uploading files
 * *
 * bug: if content-type node provided, it will provide a default content-type: application/json
 *      and treat payload as json
 * *
 * the workaround is use window.fetch to upload file, but it won't carry space auth headers when requesting
 * so the /common/upload API won't validate space auth header but will validate a custom header 'x-upload-token'
 * *
 * the x-upload-token is generated by /common/get-upload-token API
 */

import { BASE_URL } from 'src/helpers/env'
import fetch from 'src/helpers/fetch'

export const UPLOAD_TOKEN_HEADER_KEY = 'x-upload-token'

export const getUploadToken = async () =>
  fetch<string>({
    resource: 'common/upload-token',
    method: 'GET',
  })

export const uploadFile = async (file: File) => {
  const uploadToken = await getUploadToken()
  const formData = new FormData()
  formData.append('file', file)

  return await window
    .fetch(`${BASE_URL}/common/upload`, {
      method: 'POST',
      headers: {
        [UPLOAD_TOKEN_HEADER_KEY]: uploadToken,
      },
      body: formData,
    })
    .then(async (res) => {
      const { data } = await res.json()
      return data as string
    })
}
