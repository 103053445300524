import { FC, useEffect, useMemo, useState } from 'react'
import { Tag, Badge } from 'antd';
import {
    LoadingOutlined,
} from '@ant-design/icons';
import { getProjectUsersList } from 'src/api';
import { PROJECT_AUTHORITY_TYPE } from 'src/constants';
import styles from './style.scss'


interface ICollaboratorsData {
    name: string,
    role: string,
    portrait: string,

}

interface ICollaborators {
    projectId: string
}

interface IRole {
    id: number;
    name: string;
    authority_names: string[] | null;
    app_name: string;
    description: string;
    creator: string;
    operator: string;
    is_deleted: number;
    created_at: number;
    updated_at: number;
}

interface IUserRole {
    id: number;
    user_name: string;
    portrait: string;
    role: IRole;
}

// 根据用户类型，重新排序
const userSort = (arr: IUserRole[]): IUserRole[] => {
    arr.sort((a, b) => {
        // 先按照 role 是否为 null 排序
        if (a.role === null && b.role !== null) {
            return -1; // a 在 b 前面
        } else if (a.role !== null && b.role === null) {
            return 1; // a 在 b 后面
        }

        // 当 role 都不为 null 时，按照 role.name 排序
        const roleOrder = PROJECT_AUTHORITY_TYPE.map(item => item.value);
        const aRoleIndex = a.role ? roleOrder.indexOf(a.role.name) : -1;
        const bRoleIndex = b.role ? roleOrder.indexOf(b.role.name) : -1;

        return aRoleIndex - bRoleIndex;
    });
    return arr
}

const Collaborators: FC<ICollaborators> = (props) => {
    const { projectId } = props
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        async function getUsers(id: string) {
            const retUsers = await getProjectUsersList(id)
            setIsLoading(false)
            if (retUsers.code === 0) {
                setUsers(retUsers?.data?.user_roles)
            }
        }
        getUsers(projectId)
    }, [])

    const listElement = useMemo(() => {
        let combinedArray: IUserRole[] = [].concat(...Object.values(users));

        combinedArray = userSort(combinedArray)// 重新排序

        if (combinedArray && combinedArray.length) {
            return combinedArray.map((item, index) => {
                let role = item.role === null ? { name: 'super' } : item.role // 超管的role是null,需要特殊处理
                const roleName = role?.name?.replace('redhare.', '')
                return <div key={index} className={styles.collaborator}>
                    <Badge.Ribbon text={roleName} color="#2db7f5" >
                        <div className={styles.userInfo}>
                            {/* <img className={styles.portrait} src={item.portrait || DEFAULT_USER_PORTRAIT} alt={item.user_name} /> */}
                            <span className={styles.name}>{item.user_name}</span>
                        </div>
                    </Badge.Ribbon>
                </div>
            })
        }
        return null
    }, [users])

    return <div className={styles.collaboratorsOuter}>{isLoading ? <LoadingOutlined /> : listElement}</div>
}

export default Collaborators