import axios from 'axios'
import { BASE_URL } from './env'

export type FetchMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'

interface IRequestProps {
  method: FetchMethod
  resource: string
  payload?: any
  params?: any
  headers?: any
}

export const getApiFullUrl = (url: string) => `${BASE_URL}/api/${url.replace(/^\//, '')}`

export default function request<T>(props: IRequestProps): Promise<T> {
  const { method, resource, payload, params, headers } = props

  // process url
  const url = `${BASE_URL}/api/${resource.replace(/^\//, '')}`
  return axios({
    method,
    url,
    data: payload,
    params,
    headers,
    withCredentials: true, // 允许携带 Cookie
  }).then((res) => res?.data?.data)
}

