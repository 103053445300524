export interface ColumnCondition {
  column: string
  operator: '=' | '!=' | 'IN' | 'NOT IN' | 'IS' | 'IS NOT' | '>' | '>=' | '<' | '<=' | 'LIKE'
  value: any
}

export interface Condition {
  conditions: (Condition | ColumnCondition)[]
  joiner?: 'AND' | 'OR'
}

export const encodeQueryFilter = (filter: Condition) => JSON.stringify(filter)
