/*
  notes: 
    1. service worker can only register a js file from localhost or https
    2. service worker can only register a same origin js file
*/

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    console.log('trying to register service worker')
    /* 注册 service worker */
    navigator.serviceWorker
      .register(`${window.location.origin}/service-worker.js`)
      .then(function (registration) {
        (window as any).registration = registration
        console.log('ServiceWorker registration successfully to scope: ', registration.scope)
      })
      .catch(function (err) {
        console.log('ServiceWorker registration failed: ', err)
      })
  }
}

navigator.serviceWorker.addEventListener('message', (e) => {
  const data = e.data
  if (data.type === 'focus' && data.url === document.location.href) {
    console.log('focus')
    window.focus()
  }
})
