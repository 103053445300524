import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
  QueryFunction,
  QueryFunctionContext,
} from 'react-query'
import { message as antdMessage } from 'antd'

function useErrorQuery<TQueryFnData = unknown, TError = unknown, TData = TQueryFnData>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData>, 'onError'>,
  bindErrorBoundary = true,
): UseQueryResult<TData | null, TError> {
  return useQuery(
    queryKey,
    async (context: QueryFunctionContext<any>) => {
      try {
        return await queryFn(context)
      } catch (err) {
        if (err.status !== 403 && err.status !== 404) {
          // getTaskDetail 返回错误 `用户不被允许查看task`
          if (err.code !== 32022) {
            antdMessage.error(err.message)
            console.error(err)
          }
          return null
        }
        if (!bindErrorBoundary) return null
        throw err
      }
    },
    /*
     * TODO: retry、staleTime
     */
    {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
      ...options,
    },
  )
}

export default useErrorQuery
