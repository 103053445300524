export const DEFAULT_INTERCEPTOR = {
  WILL_FETCH: `function willFetch(options) {
  return options
}`,
  DATA_HANDLER: `function dataHandler(res) {
  return res.data
}`,
  ERROR_HANDLER: `function errorHandler(error) {
  throw error
}`,
}
