import { ENV_ENUM } from "src/constants"
import { ENV } from "./env"


export const gotoLogin = () => {
	let loginPage: string
	if (ENV === ENV_ENUM.LIVE) {
		loginPage = 'https://cdn.redhare.cc/rs/prod/pc/page/208/index.html'
	} else {
		loginPage = 'https://cdn.redhare.cc/rs/test/pc/page/208/index.html'
	}
	window.location.href = `${loginPage}?redirect=${encodeURIComponent(
		window.location.href
	)}`
}
