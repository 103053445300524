import { Button, Menu, Popover } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { FC, useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { logout } from 'src/api'
import { REQ_STATUS } from 'src/constants'
import { DEFAULT_PROFILE_PICTURE, WECHAT_QRCODE } from 'src/constants/index'
import { ROUTES } from 'src/constants/routes'
import { getUserName } from 'src/helpers/getUserName'
import { gotoLogin } from 'src/helpers/login'
import {
  ApiOutlined,
  AppstoreOutlined,
  BlockOutlined,
  DashboardOutlined,
  HddOutlined,
  MonitorOutlined,
  PlaySquareOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import styles from './styles.scss'

const LeftPanel: FC<{ isSuper: boolean }> = ({ isSuper }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const [buttonText, setButtonText] = useState('退出登录')

  const menuItems = useMemo(
    () =>
      [
        {
          key: 'create',
          type: 'group',
          label: '创作',
        },
        {
          key: ROUTES.APP,
          label: '应用列表',
          icon: <AppstoreOutlined />,
        },
        {
          key: ROUTES.TEMPLATE,
          label: '区块模板',
          icon: <BlockOutlined />,
        },
        {
          key: 'backend-lowcode',
          label: (
            <a
              href="https://cdn.redhare.cc/rs/prod/pc/portal/123/index.html#/68vj"
              target="_blank"
              rel="noopener noreferrer"
            >
              后端低码
            </a>
          ),
          icon: <ApiOutlined />,
        },
        {
          key: 'study',
          type: 'group',
          label: '学习',
        },
        {
          key: 'doc',
          label: (
            <a href="https://doc.pc.redhare.cc/" target="_blank" rel="noopener noreferrer">
              文档教程
            </a>
          ),
          icon: <ReadOutlined />,
        },
        {
          key: ROUTES.COURSE,
          label: '视频教程',
          icon: <PlaySquareOutlined />,
        },
        isSuper
          ? {
              key: 'manage',
              type: 'group',
              label: '管理',
            }
          : undefined,
        isSuper
          ? {
              key: ROUTES.MATERIAL,
              label: '物料管理',
              icon: <HddOutlined />,
            }
          : undefined,
        isSuper
          ? {
              key: ROUTES.DATA_CENTER,
              label: '数据中心',
              icon: <DashboardOutlined />,
            }
          : undefined,
        {
          key: 'tool',
          type: 'group',
          label: '工具',
        },
        {
          key: 'market-place-cos',
          label: (
            <a
              href="https://cdn.redhare.cc/rs/prod/pc/page/209/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              文件图床工具
            </a>
          ),
          icon: <UploadOutlined />,
        },
        {
          key: 'market-place-xlight',
          label: (
            <a href="https://www.redhare.cc/xlight/#/" target="_blank" rel="noopener noreferrer">
              页面性能工具
            </a>
          ),
          icon: <MonitorOutlined />,
        },
        {
          key: 'contact',
          type: 'group',
          label: '联系',
        },
        {
          key: 'inquiry',
          label: (
            <Popover
              placement="right"
              content={
                <div className={styles.connectUs}>
                  <div
                    className={styles.wechat}
                    style={{ backgroundImage: `url(${WECHAT_QRCODE})` }}
                  ></div>
                  <span>反馈问题</span>
                  <span>咨询帮助</span>
                  <span>联系我们</span>
                  <span>微信扫码二维码</span>
                </div>
              }
            >
              <div onClick={(e) => e.stopPropagation()}>
                咨询 <QuestionCircleOutlined />
              </div>
            </Popover>
          ),
          icon: <QrcodeOutlined />,
        },
      ].filter(Boolean),
    [isSuper],
  )

  const onMenuClick: MenuProps['onClick'] = (e) => {
    if (Object.values(ROUTES).includes(e.key)) {
      history.push({
        pathname: e.key,
      })
    }
  }

  const handleLogout = useCallback(async () => {
    setButtonText('正在退出账号...')
    try {
      const retLogout: any = await logout()

      if (retLogout?.code === REQ_STATUS.SUCCESS) {
        gotoLogin()
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <img
          className={styles.logoPicture}
          src="https://www.redhare.cc/img/logo.d6b4a91b.png"
          alt=""
        />
      </div>
      <div className={styles.main}>
        <Menu
          className={styles.menu}
          onClick={onMenuClick}
          selectedKeys={[pathname]}
          mode="inline"
          items={menuItems}
        />
      </div>
      <div className={styles.bottom}>
        <Popover
          placement="top"
          content={
            <Button className={styles.button} onClick={handleLogout}>
              {buttonText}
            </Button>
          }
        >
          <div className={styles.profile}>
            <div
              className={styles.avartar}
              style={{ backgroundImage: `url(${DEFAULT_PROFILE_PICTURE})` }}
            />
            <div className={styles.userName}>{getUserName()}</div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default LeftPanel
