import { DesktopOutlined, LaptopOutlined, MobileOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import { PageType } from 'src/api'

export const PageTypeIcon = ({ type }: { type: PageType }) => {
  return (
    <Tooltip title={type === PageType.H5 ? 'H5应用' : 'PC应用'}>
      <Tag>
        {type === PageType.H5 ? (
          <MobileOutlined style={{ fontSize: '16px' }} />
        ) : (
          <LaptopOutlined style={{ fontSize: '16px' }} />
        )}
      </Tag>
    </Tooltip>
  )
}

export const MaterialTypeIcon = ({ type }: { type: PageType }) => {
  return (
    <Tooltip title={type === PageType.H5 ? 'H5物料' : type === PageType.PC ? 'PC物料' : '通用物料'}>
      <Tag>
        {type === PageType.H5 ? (
          <MobileOutlined />
        ) : type === PageType.PC ? (
          <LaptopOutlined />
        ) : (
          <DesktopOutlined />
        )}
      </Tag>
    </Tooltip>
  )
}
