import { FC } from 'react'
import { IGetDataCenterDataResponse } from 'src/api'
import { format } from 'src/helpers/time'
import styles from './recentlyPublish.scss'

interface IRecentlyPublishProps {
  datasource: IGetDataCenterDataResponse['data']['recentlyPublish']
}

const RecentlyPublish: FC<IRecentlyPublishProps> = (props) => {
  const { datasource } = props
  return (
    <div className={styles.historyRoot}>
      <div className={styles.gap} />
      <div className={styles.content}>
        <span className={styles.title}>近期发布</span>
        {datasource &&
          datasource.length &&
          datasource.map((item, index) => {
            return (
              <div key={index} className={styles.newPublishArea}>
                <span className={styles.heightFont}>{item.createdBy}</span>
                <span>&nbsp;于&nbsp;</span>
                <span>{format(item.createdAt, 'MM-DD hh:mm')}，</span>
                <span> 在&nbsp;</span>
                <span className={styles.heightFont}> {item.env}</span>
                <span> &nbsp;</span>
                <span>发布了&nbsp;</span>
                <span className={styles.ownerId}>{item.ownerId}</span>
                <span>&nbsp;项目，备注：</span>
                <span>{item.changelog}</span>
              </div>
            )
          })}
      </div>
      <div className={styles.gap} />
    </div>
  )
}

export default RecentlyPublish
