import { FC, useState } from 'react'
import styles from './style.scss'
import { Input } from 'antd'
import ComponentTemplateTable from './components/ComponentTemplateTable'

const { Search } = Input

export enum TemplateType {
  PUBLIC = 'Public',
  PRIVATE = 'Private',
}

const ComponentTemplate: FC = () => {
  const [searchKeyWord, setSearchKeyWord] = useState('')

  const onSearch = (value: string) => {
    setSearchKeyWord(value)
  }

  return (
    <div className={styles.root}>
      <div>
        <Search
          style={{ width: '228px' }}
          placeholder="模板名称"
          allowClear
          onSearch={onSearch}
          className={styles.search}
        />
      </div>
      <ComponentTemplateTable
        templateTableType={TemplateType.PRIVATE}
        searchKeyWord={searchKeyWord}
      />
    </div>
  )
}

export default ComponentTemplate
