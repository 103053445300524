import {
  Steps as AntdSteps,
  Typography,
  Space,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  message,
} from 'antd'
import { QuestionCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import {
  getPagePublishInfo,
  getPortalPublishInfo,
  IPagePublishInfo,
  PagePublishEnvEnum,
  PagePublishStatusEnum,
  publishPage,
  publishPortal,
} from 'src/api'
import { getPage, getPortal } from 'src/api'
import Loading from 'src/components/Loading'
import { ReactComponent as TestEnvSvg } from 'src/assets/publish-env/test-env.svg'
import { ReactComponent as LiveEnvSvg } from 'src/assets/publish-env/live-env.svg'
import { ReactComponent as DevEnvSvg } from 'src/assets/publish-env/dev-env.svg'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'

import styles from './style.scss'
import { getSchemaByPageId, getSchemaByPortalId, ISchema } from 'src/api'
import useErrorMutation from 'src/hooks/useReactQuery/useErrorMutation'
import { noop } from 'src/helpers/function'
import { format } from 'src/helpers/time'
import classNames from 'classnames'
import { INSTANCE_TYPE } from 'src/constants'
import { AnyFunc } from 'src/types/custom'

const genTitle = (title: string, tip: string) => (
  <Space>
    {title}
    <Tooltip title={tip}>
      <QuestionCircleOutlined />
    </Tooltip>
  </Space>
)

interface IStepPublishInfo {
  updateAt: number
  updateBy: string
}

const genDescription = (
  data: IStepPublishInfo,
  env: PagePublishEnvEnum,
  button: ReactNode,
  className = '',
) =>
  !!data ? (
    <div key={env} className={classNames(styles.stepDescription, className)}>
      <Typography.Text type="secondary">
        {env === PagePublishEnvEnum.dev ? '更新时间：' : '创建时间：'} {format(data.updateAt)}
      </Typography.Text>
      <Typography.Text type="secondary">
        {env === PagePublishEnvEnum.dev ? '更新人：' : '创建人：'} {data.updateBy}
      </Typography.Text>
      {button}
    </div>
  ) : null

export interface IStepsProps {
  instanceId: string
  projectId: string
  instanceType: INSTANCE_TYPE
  onPublish?: AnyFunc
}

const getInstanceData = async (pageId, projectId, instanceType) => {

  const getInstanceFn = instanceType === INSTANCE_TYPE.PORTAL ? getPortal : getPage

  const pageInfo = await getInstanceFn(pageId, projectId)
  const { publishStatus: current = PagePublishStatusEnum.Blank } = pageInfo ?? {}

  type IPublishInfo = {
    [props in PagePublishEnvEnum]?: IStepPublishInfo
  }

  let publishInfo: IPublishInfo = {}
  if (current !== PagePublishStatusEnum.Blank) {
    publishInfo = {
      [PagePublishEnvEnum.dev]: {
        updateAt: pageInfo.updatedAt,
        updateBy: pageInfo.updatedBy,
      },
    }
    const getPublishInfoFn =
      instanceType === INSTANCE_TYPE.PORTAL ? getPortalPublishInfo : getPagePublishInfo
    if (current === PagePublishStatusEnum.PublishedToLive) {
      const [publishInfoOfTest, publishInfoOfLive] = await Promise.all([
        getPublishInfoFn({ pageId, projectId, env: PagePublishEnvEnum.test }),
        getPublishInfoFn({ pageId, projectId, env: PagePublishEnvEnum.live }),
      ])
      publishInfo[PagePublishEnvEnum.test] = {
        updateAt: publishInfoOfTest?.createdAt,
        updateBy: publishInfoOfTest?.createdBy,
      }
      publishInfo[PagePublishEnvEnum.live] = {
        updateAt: publishInfoOfLive?.createdAt,
        updateBy: publishInfoOfLive?.createdBy,
      }
    } else if (current === PagePublishStatusEnum.PublishedToTest) {
      const publishInfoOfTest = await getPublishInfoFn({
        pageId,
        projectId,
        env: PagePublishEnvEnum.test,
      })
      publishInfo[PagePublishEnvEnum.test] = {
        updateAt: publishInfoOfTest?.createdAt,
        updateBy: publishInfoOfTest?.createdBy,
      }
    }
  }
  const result = { pageInfo, publishInfo, current }
  return result
}

const Steps: React.FC<IStepsProps> = ({ instanceId, projectId, instanceType, onPublish = noop }) => {
  const { data: instanceData, isLoading } = useErrorQuery(
    ['getPage', 'getPagePublishInfo', instanceId],
    () => getInstanceData(instanceId, projectId, instanceType),
    {
      staleTime: -1,
      cacheTime: -1,
    },
  )

  const { publishInfo, current } = instanceData || {}

  const publishFn = instanceType === INSTANCE_TYPE.PORTAL ? publishPortal : publishPage
  const { mutateAsync: mutatePublish, isLoading: publishing } = useErrorMutation(
    (params: IPagePublishInfo) => publishFn(params),
  )

  const getPageSchemaFn =
    instanceType === INSTANCE_TYPE.PORTAL ? getSchemaByPortalId : getSchemaByPageId
  const { data: pageSchema } = useErrorQuery(
    ['getPageSchemaByPageId', instanceId],
    () => getPageSchemaFn(instanceId, projectId),
    {
      onSuccess: (data) => {
        checkBlankPage(data)
      },
    },
  )

  const checkBlankPage = useCallback((pageSchema: ISchema) => {
    if (!pageSchema?.id) {
      // TODO(BE): can't publish
      message.warning('Current page is a blank page, please edit it before publishing!')
      return true
    }
    return false
  }, [])

  const publishBtnText = useMemo(
    () => ({
      [PagePublishStatusEnum.NotPublished]: '发布到测试环境',
      [PagePublishStatusEnum.PublishedToTest]: '发布到正式环境',
      [PagePublishStatusEnum.PublishedToLive]: '正式环境已发布',
    }),
    [],
  )

  const publishDisable = useMemo(() => current === PagePublishStatusEnum.Blank, [current])

  const handlePublish = useCallback(async () => {
    if (
      current !== PagePublishStatusEnum.NotPublished &&
      current !== PagePublishStatusEnum.PublishedToTest
    )
      return
    if (checkBlankPage(pageSchema)) return

    setModalVisible(true)
  }, [checkBlankPage, current, pageSchema])

  const steps = useMemo(
    () => [
      {
        title: genTitle('开发环境', '开发环境'),
        key: PagePublishEnvEnum.dev,
        icon:
          current === PagePublishStatusEnum.NotPublished && publishing ? (
            <LoadingOutlined />
          ) : (
            <DevEnvSvg />
          ),
        button: [PagePublishStatusEnum.Blank, PagePublishStatusEnum.NotPublished].includes(
          current,
        ) &&
          !publishDisable && (
            <div className={styles.action}>
              <Button
                style={{ width: 160 }}
                loading={publishing}
                type="primary"
                onClick={() => handlePublish()}
              >
                {publishBtnText[PagePublishStatusEnum.NotPublished]}
              </Button>
            </div>
          ),
      },
      {
        title: genTitle(
          '测试环境',
          '用来测试页面逻辑和联调接口',
        ),
        key: PagePublishEnvEnum.test,
        icon:
          current === PagePublishStatusEnum.PublishedToTest && publishing ? (
            <LoadingOutlined />
          ) : (
            <TestEnvSvg />
          ),
        button: current === PagePublishStatusEnum.PublishedToTest && !publishDisable && (
          <div className={styles.action}>
            <Button
              style={{ width: 160 }}
              loading={publishing}
              type="primary"
              onClick={() => handlePublish()}
            >
              {publishBtnText[PagePublishStatusEnum.PublishedToTest]}
            </Button>
          </div>
        ),
      },
      {
        title: genTitle(
          '正式环境',
          '提供给用户使用',
        ),
        key: PagePublishEnvEnum.live,
        icon:
          current === PagePublishStatusEnum.PublishedToLive && publishing ? (
            <LoadingOutlined />
          ) : (
            <LiveEnvSvg />
          ),
        button: current === PagePublishStatusEnum.PublishedToLive && (
          <div className={styles.action}>
            <Button disabled> {publishBtnText[PagePublishStatusEnum.PublishedToLive]} </Button>
          </div>
        ),
      },
    ],
    [current, handlePublish, publishBtnText, publishDisable, publishing],
  )

  /**
   * publish modal
   */
  const [form] = Form.useForm<{ changelog: string }>()

  const [modalVisible, setModalVisible] = useState(false)

  const handleModalOk = useCallback(async () => {
    let env!: PagePublishEnvEnum
    switch (current) {
      case PagePublishStatusEnum.NotPublished:
        env = PagePublishEnvEnum.test
        break
      case PagePublishStatusEnum.PublishedToTest:
        env = PagePublishEnvEnum.live
        break
      default:
        break
    }
    const { changelog } = await form.validateFields()
    mutatePublish(
      {
        pageId: instanceId,
        projectId: projectId,
        changelog,
        schemaId: pageSchema.id,
        env,
      },
      {
        onSuccess() {
          setModalVisible(false)
          form.resetFields()
          onPublish()
        },
      },
    )
  }, [current, form, mutatePublish, onPublish, instanceId, pageSchema])

  const handleModalCancel = useCallback(() => {
    setModalVisible(false)
  }, [])

  return (
    <>
      <Loading renderChildrenWhenLoading spinning={isLoading}>
        <div className={styles.root}>
          <Space direction="vertical" className={styles.progress}>
            <Typography.Title className={styles.progressTitle}>发布流程</Typography.Title>
            <div className={styles.progressContent}>
              <AntdSteps current={current}>
                {steps.map((step) => (
                  <AntdSteps.Step key={step.key} title={step.title} icon={step.icon} />
                ))}
              </AntdSteps>
              <div className={styles.content}>
                {steps.map((step, idx) =>
                  genDescription(
                    publishInfo?.[step.key],
                    step.key,
                    step.button,
                    styles[`des${idx}`],
                  ),
                )}
              </div>
            </div>
          </Space>
        </div>
      </Loading>
      <Modal
        visible={modalVisible}
        title="发布"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        destroyOnClose
        footer={[
          <Button key="back" onClick={handleModalCancel}>
            取消
          </Button>,
          <Button key="submit" type="primary" loading={publishing} onClick={handleModalOk}>
            {publishBtnText[current]}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="更新日志"
            name="changelog"
            rules={[{ required: true, message: "必填" }]}
          >
            <Input.TextArea placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Steps
