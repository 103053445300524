import fetch from 'src/helpers/fetch'
import { IPagingParams, IPagingRes } from 'src/types/custom'

/**
 * Page Publish Management
 */
export enum PagePublishStatusEnum {
	Blank = -1,
	NotPublished,
	PublishedToTest,
	PublishedToLive,
}
export enum PagePublishEnvEnum {
	dev = 'dev',
	test = 'test',
	live = 'live',
}
export interface IPagePublish {
	id: string
	pageId: string
	changelog: string
	schemaId: string
	env: PagePublishEnvEnum
	createdAt: number
	createdBy: string
}
export interface IPagePublishInfo {
	pageId: string
	projectId: string
	changelog: string
	schemaId: string
	env: PagePublishEnvEnum
}

const transformPageToPortal = (params: Record<string, any>) => {
	const newParams: any = { ...params }
	newParams.portalId = newParams.pageId
	delete newParams.pageId
	return newParams
}

export const publishPage = async (data: IPagePublishInfo) =>
	fetch({
		method: 'POST',
		resource: 'page-publish/page',
		payload: data,
	})

export const publishPortal = async (data: IPagePublishInfo) =>
	fetch({
		method: 'POST',
		resource: 'page-publish/portal',
		payload: transformPageToPortal(data),
	})

export interface IPageInfoParams {
	pageId: string
	projectId: string
	env: PagePublishEnvEnum
	keyword?: string
}

export const getPagePublishList = async (
	data: IPagingParams & IPageInfoParams
) =>
	fetch<IPagingRes<IPagePublish>>({
		method: 'GET',
		resource: 'page-publish/page-list',
		params: data,
	})

export const getPortalPublishList = async (
	data: IPagingParams & IPageInfoParams
) =>
	fetch<IPagingRes<IPagePublish>>({
		method: 'GET',
		resource: 'page-publish/portal-list',
		params: transformPageToPortal(data),
	})

export const getPagePublishInfo = async (data: IPageInfoParams) =>
	fetch<IPagePublish>({
		method: 'GET',
		resource: `page-publish/page/${data.pageId}`,
		params: data,
	})

export const getPortalPublishInfo = async (data: IPageInfoParams) =>
	fetch<IPagePublish>({
		method: 'GET',
		resource: `page-publish/portal/${data.pageId}`,
		params: transformPageToPortal(data),
	})

export interface IProjectPublish {
	env: PagePublishEnvEnum
	changelog: string
	projectId: string
}

export interface IProjectPublishResult {
	results: { id: string; name: string; success: boolean; message?: string }[]
}

export const publishAll = async (data: IProjectPublish) =>
	fetch<IProjectPublishResult>({
		method: 'POST',
		resource: `page-publish/project/all`,
		payload: data,
	})
