/* eslint-disable multiline-comment-style */
import { FC, useCallback, useEffect, useMemo } from 'react'
import { Modal, ModalProps, Form, Input, Space, Image, Button } from 'antd'
import { IPage, IPageInfo, copyPage } from 'src/api'
import { useProjectContext } from '../..'
import DefaultLogoUrl from 'src/assets/page-default-logo.png'
import { CopyOutlined } from '@ant-design/icons'

import styles from './style.scss'
import { useMutation } from 'react-query'
import { AnyFunc } from 'src/types/custom'

export enum EditTypeEnum {
  create = 'create',
  modify = 'modify',
}

export enum CreateTypeEnum {
  blank = 'blank',
  template = 'template',
}

export interface IEditProps {
  visible: boolean
  onOk: AnyFunc
  onCancel: AnyFunc
  data?: IPage
}

type IFormData = IPageInfo

const PageCopy: FC<IEditProps> = (props) => {
  const { visible, onOk, onCancel, data } = props
  const [form] = Form.useForm<IFormData>()
  const { id: projectId } = useProjectContext()

  const setInitialFormVal = useCallback(() => {
    const { name, materialIds } = data ?? {}
    const formData = { name: `Copy of ${name}`, description: '', materialIds, projectId }
    form.setFieldsValue(formData)
  }, [data, form, projectId])

  useEffect(() => {
    setInitialFormVal()
  }, [data, form, projectId, setInitialFormVal])

  const { mutateAsync: mutateCopyPage, isLoading: isCoping } = useMutation((data: IPage) =>
    copyPage(data),
  )

  const handleOk: ModalProps['onOk'] = useCallback(async () => {
    const formData = await form.validateFields().catch(console.error)
    if (!formData) {
      return
    }
    await mutateCopyPage({ ...data, ...formData })
    onOk()
  }, [form, onOk, mutateCopyPage, data])

  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onCancel()
    setInitialFormVal()
  }, [onCancel, setInitialFormVal])

  const modalProps = useMemo(
    () => ({
      className: styles.modal,
      title: (
        <Space size="small">
          <CopyOutlined />
          {`Duplicate ${data.name}`}
        </Space>
      ),
      visible,
      onOk: handleOk,
      onCancel: handleCancel,
      footer: [
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" loading={isCoping} onClick={handleOk}>
          保存
        </Button>,
      ],
    }),
    [data.name, handleCancel, handleOk, isCoping, visible],
  )

  return (
    <Modal {...modalProps} destroyOnClose>
      <Form form={form} layout="vertical">
        <Form.Item required label="Logo">
          <div className={styles.logoWrapper}>
            <Image preview={false} width={48} src={DefaultLogoUrl} alt="Logo" />
          </div>
        </Form.Item>
        <Form.Item
          label="名称"
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="描述"
          name="description"
        >
          <Input.TextArea placeholder="请输入" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PageCopy
