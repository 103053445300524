import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Drawer, Modal, Space, Table, TableColumnsType, Tag, Tooltip } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { IMaterial, getSystemMaterials, patchProject } from 'src/api'
import { openNpm } from 'src/helpers/link'
import { ProjectContext } from 'src/pages/projectDetail'
import MaterialForm from './material-form'
import { Icon } from '@alifd/next'

const Materials = () => {
  const { project, refresh } = useContext(ProjectContext)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [materialFormOpen, setMaterialFormOpen] = useState(false)
  const [systemMaterials, setSystemMaterials] = useState<IMaterial[]>([])
  const [isAdd, setIsAdd] = useState(false)
  const [current, setCurrent] = useState<IMaterial>()

  useEffect(() => {
    const init = async () => {
      const systemMaterials = await getSystemMaterials()
      setSystemMaterials(systemMaterials)
    }
    init()
  }, [])

  const handleAdd = () => {
    setMaterialFormOpen(true)
    setIsAdd(true)
    setCurrent(undefined)
  }

  const handleSaveMaterial = async (material: IMaterial) => {
    const materials = project?.materials || []
    if (isAdd) {
      materials.push(material)
    } else {
      const index = materials.indexOf(current)
      if (index > -1) {
        materials.splice(index, 1, material)
      }
    }
    project.materials = materials
    setLoading(true)
    await patchProject(project)
    await refresh()
    setLoading(false)
    setMaterialFormOpen(false)
  }

  const handleEdit = (material: IMaterial) => {
    setMaterialFormOpen(true)
    setIsAdd(false)
    setCurrent(material)
  }

  const handleDelete = (material: IMaterial) => {
    Modal.confirm({
      content: '确认要删除吗？',
      onOk: async () => {
        setLoading(true)
        const materials = project?.materials || []
        const index = materials.indexOf(material)
        materials.splice(index, 1)
        project.materials = materials
        await patchProject(project)
        await refresh()
        setLoading(false)
        setMaterialFormOpen(false)
      },
    })
  }

  const columns: TableColumnsType<IMaterial> = [
    {
      title: '包名',
      dataIndex: 'name',
      render: (packageName: string, dep) => (
        <Tag onClick={() => openNpm(packageName)}>{packageName}</Tag>
      ),
    },
    {
      title: '版本号',
      dataIndex: 'version',
    },
    {
      title: '操作',
      render: (_, material, index) =>
        systemMaterials.some((item) => item.name === material.name) ? (
          <Space>
            <Tooltip title="默认物料自动更新">
              <Button disabled icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title="默认物料不可删除">
              <Button disabled icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Button icon={<EditOutlined />} onClick={() => handleEdit(material)} />
            <Button icon={<DeleteOutlined />} onClick={() => handleDelete(material)} />
          </Space>
        ),
    },
  ]

  return (
    <>
      <Drawer
        width="800px"
        open={open}
        title="物料库"
        onClose={() => setOpen(false)}
        footer={
          <Space>
            <Button onClick={() => setOpen(false)} loading={loading}>
              关闭
            </Button>
          </Space>
        }
      >
        <Alert type="info" message="物料库是由包含了组件和组件的低代码描述的npm包。" />
        <Table
          columns={columns}
          dataSource={systemMaterials.concat(project?.materials || [])}
          footer={() => (
            <Button
              style={{ display: 'block', width: '100%' }}
              icon={<PlusOutlined />}
              onClick={() => handleAdd()}
            >
              添加物料库
            </Button>
          )}
        />
        <MaterialForm
          systemMaterials={systemMaterials}
          isAdd={isAdd}
          visible={materialFormOpen}
          current={current}
          onCancel={() => setMaterialFormOpen(false)}
          onOk={(dep) => handleSaveMaterial(dep)}
        />
      </Drawer>
      <Button
        onClick={() => setOpen(true)}
        icon={<Icon type="zujianku" size="small" style={{ marginRight: '8px' }} />}
      >
        物料库
      </Button>
    </>
  )
}

export default Materials
