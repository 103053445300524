import { FC } from 'react'
import styles from './style.scss'

const Course: FC = () => {
  return (
    <div className={styles.root}>
      <div className={styles.baseVideoOuter}>
        <a
          className={styles.baseVideo}
          target='_blank'
          href='https://www.bilibili.com/video/BV1Rw411B79s/'
          rel='noreferrer'>
          <img className={styles.img} src='https://cdn.redhare.cc/rs/external/customer/1698/WechatIMG12.jpg' alt='' />
        </a>
        <div className={styles.baseVideoDescription}>
          <div>
            <div className={styles.title}>平台功能介绍和基础使用指导</div>
            <div className={styles.description}>
              通过基础教程的学习，您可以基本了解赤兔的功能，这包括：如何新建一个项目到如何发布，也包括一些简单的组件的使用和配置。总之，如果您想大致了解赤兔正在做的事情，您可以观看此视频教程。
            </div>
            <a
              target='_blank'
              href='https://www.bilibili.com/video/BV1Rw411B79s/'
              className={styles.button}
              rel='noreferrer'>
              观看视频
            </a>
          </div>
        </div>
      </div>
      <div className={styles.advencedVideoOuter}>
        <div className={styles.advancedDescription}>
          <div className={styles.title}>平台高级进阶功能的使用指导</div>
          <div className={styles.description}>
            在高级功能里，您可以深入理解数据是如何获取，数据如何绑定在页面中，页面的不同地方数据如何互相引用。同时，您可以通过高阶功能的演示来明白如何处理自定义逻辑，如何应对复杂的数据绑定。当然，这里并不会完整介绍所有功能，需要更详细的单个能力的介绍，您可以期待后续更新的教程。
          </div>
          <a
            target='_blank'
            href='https://www.bilibili.com/video/BV1He411d7n6/?vd_source=d233230902be86bbbf795afab8104336'
            className={styles.button}
            rel='noreferrer'>
            观看视频
          </a>
        </div>
        <a
          target='_blank'
          href='https://www.bilibili.com/video/BV1He411d7n6/?vd_source=d233230902be86bbbf795afab8104336'
          className={styles.advancedVideo}
          rel='noreferrer'>
          <div className={styles.videoLeftOuter}>
            <img src='https://cdn.redhare.cc/rs/external/customer/1698/WechatIMG16.jpg' alt='' />
          </div>
          <div className={styles.videoRightOuter}>
            <img src='https://cdn.redhare.cc/rs/external/customer/1698/WechatIMG13.jpg' alt='' />
            <img src='https://cdn.redhare.cc/rs/external/customer/1698/WechatIMG14.jpg' alt='' />
          </div>
        </a>
      </div>
    </div>
  )
}

export default Course
