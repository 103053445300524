import { FC } from 'react'
import { getPageList } from 'src/api'
import { getProjectList } from 'src/api'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'
import { Spin, Tooltip, Empty, Space } from 'antd'
import styles from './style.scss'
import PageCard from '../../../projectDetail/components/PageCard'
import { ROUTES } from 'src/constants/routes'
import Card from '../AppCard'
import { INSTANCE_TYPE, DEFAULT_REQ_PROJECT_MAX_SIZE, DEFAULT_PAGE_NUMBER } from 'src/constants'
import { EnterOutlined, EyeOutlined } from '@ant-design/icons'
import { encodeQueryFilter } from 'src/helpers/queryFilter'

interface ICombineSearchResultProps {
  keyword: string
}

const CombineSearchResult: FC<ICombineSearchResultProps> = ({ keyword }) => {
  const { data, isLoading: isFetchingModules } = useErrorQuery(
    ['getPageList', keyword],
    () =>
      getPageList({
        pageSize: DEFAULT_REQ_PROJECT_MAX_SIZE,
        current: DEFAULT_PAGE_NUMBER,
        filter: encodeQueryFilter({
          joiner: 'OR',
          conditions: [
            {
              column: 'name',
              operator: 'LIKE',
              value: keyword,
            },
            {
              column: 'id',
              operator: '=',
              value: Number(keyword),
            },
          ],
        }),
      }),
  )

  const { data: applicationData, isLoading: isFetchingApplications } = useErrorQuery(
    ['getProjectList', keyword],
    () =>
      getProjectList({
        pageSize: DEFAULT_REQ_PROJECT_MAX_SIZE,
        current: DEFAULT_PAGE_NUMBER,
        ...(keyword
          ? {
            filter: encodeQueryFilter({
              conditions: [
                {
                  column: 'name',
                  operator: 'LIKE',
                  value: keyword,
                },
              ],
            }),
          }
          : {}),
      }),
  )

  const toApplicationPage = (applicationId: string) => {
    window.open(`/#${ROUTES.APP_DETAIL}?id=${applicationId}`, '_blank')
  }

  const toModuleEditPage = (moduleId: string, projectId: string) => {
    window.open(
      `/#${ROUTES.WORKBENCH}?id=${moduleId}&projectId=${projectId}&instanceType=${INSTANCE_TYPE.PAGE}`,
      '_blank',
    )
  }

  return (
    <Spin spinning={isFetchingModules || isFetchingApplications}>
      <div className={styles.container}>
        <h1>应用列表</h1>
        {applicationData?.records?.length ? (
          <div className={styles.list}>
            {applicationData?.records?.map((application) => (
              <div key={application.id}>
                <Card
                  key={application.id}
                  project={application}
                  className={styles.applicationCard}
                />
              </div>
            ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        <h1>页面列表</h1>
        {data?.records?.length ? (
          <div className={styles.list}>
            {data?.records
              ?.sort((a, b) => Number(a.id) - Number(b.id))
              ?.map((page) => (
                <div key={page.id} className={styles.item}>
                  <PageCard key={page.id} page={page} />

                  <div className={styles.mask}>
                    <div className={styles.actions}>
                      <Space size={48}>
                        <Tooltip title="进入应用">
                          <EnterOutlined
                            className={styles.icon}
                            onClick={() => toApplicationPage(page.projectId)}
                          />
                        </Tooltip>
                        <Tooltip title="查看页面">
                          <EyeOutlined
                            className={styles.icon}
                            onClick={() => toModuleEditPage(page.id, page.projectId)}
                          />
                        </Tooltip>
                      </Space>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Spin>
  )
}

export default CombineSearchResult
