// 文件下载
export const downloadFile = (fileUrl: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  // 设置下载属性
  if (fileName) {
    link.download = fileName;
  }

  // 模拟点击链接进行下载
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}