/* eslint-disable multiline-comment-style */
import { FC, useCallback, useEffect, useMemo } from 'react'
import { Modal, ModalProps, Form, Input, Space, Image, Button } from 'antd'
import { AppstoreAddOutlined } from '@ant-design/icons'
import { addPage, IPage, IPageInfo, patchPage } from 'src/api'
import { useProjectContext } from '../..'
import DefaultLogoUrl from 'src/assets/page-default-logo.png'

import styles from './style.scss'
import { useMutation } from 'react-query'
import { AnyFunc } from 'src/types/custom'

export enum EditTypeEnum {
  create = 'create',
  modify = 'modify',
}

export enum CreateTypeEnum {
  blank = 'blank',
  template = 'template',
}

export interface IEditProps {
  visible: boolean
  onOk: AnyFunc
  onCancel: AnyFunc
  title: string
  type?: EditTypeEnum
  createType?: CreateTypeEnum
  data?: IPage
}

type IFormData = IPageInfo

const PageEdit: FC<IEditProps> = (props) => {
  const { visible, onOk, onCancel, title, type = EditTypeEnum.modify, data } = props
  const [form] = Form.useForm<IFormData>()
  const { id: projectId } = useProjectContext()

  const setInitialFormVal = useCallback(() => {
    const { name, description, materialIds } = data ?? {}
    const formData =
      type === EditTypeEnum.modify
        ? { name, description, materialIds, projectId }
        : { name: '', description: '', materialIds: [], projectId }
    form.setFieldsValue(formData)
  }, [data, form, projectId, type])

  useEffect(() => {
    setInitialFormVal()
  }, [data, form, projectId, setInitialFormVal, type])

  const { mutateAsync: mutateAddPage, isLoading: isAdding } = useMutation((data: IPageInfo) =>
    addPage(data),
  )

  const { mutateAsync: mutatePatchPage, isLoading: isPatching } = useMutation((data: IPage) =>
    patchPage(data),
  )

  const handleOk: ModalProps['onOk'] = useCallback(async () => {
    const formData = await form.validateFields()

    switch (type) {
      case EditTypeEnum.create:
        // TODO(BE/FE): materialIds
        await mutateAddPage({ ...formData, projectId, materialIds: [] })
        break
      case EditTypeEnum.modify:
        await mutatePatchPage({ ...data, ...formData })
        break;
      default:
        break
    }
    onOk()
    if (type === EditTypeEnum.create) {
      form.resetFields()
    }
  }, [form, type, onOk, mutateAddPage, projectId, mutatePatchPage, data])

  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onCancel()
    setInitialFormVal()
  }, [onCancel, setInitialFormVal])

  const modalProps = useMemo(
    () => ({
      className: styles.modal,
      title: (
        <Space size="small">
          <AppstoreAddOutlined />
          {title}
        </Space>
      ),
      visible,
      onOk: handleOk,
      onCancel: handleCancel,
      footer: [
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" loading={isAdding || isPatching} onClick={handleOk}>
          保存
        </Button>,
      ],
    }),
    [handleCancel, handleOk, isAdding, isPatching, title, visible],
  )

  return (
    <Modal {...modalProps} destroyOnClose>
      <Form form={form} layout="vertical">
        <Form.Item required label="Logo">
          <div className={styles.logoWrapper}>
            <Image preview={false} width={48} src={DefaultLogoUrl} alt="Logo" />
          </div>
        </Form.Item>
        <Form.Item
          label="名称"
          name="name"
          rules={[{ required: true }]}
        >
          <Input placeholder="请填写名称" />
        </Form.Item>
        <Form.Item
          label="描述"
          name="description"
        >
          <Input.TextArea placeholder="请填写描述" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PageEdit
