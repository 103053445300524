
import { FC, useState, useEffect } from 'react'
import { Alert } from 'antd'
import { getRedhareNotice } from 'src/api'

import styles from './styles.scss'

const RedhareNotice: FC = () => {
    const [notice, setNotice] = useState('');

    useEffect(() => {
        async function getNotice() {
            const retNotice = await getRedhareNotice()
            if (retNotice.code === 0) {
                setNotice(retNotice.message)
            }
        }
        getNotice()
    }, [])


    return (
        notice ? <Alert className={styles.container} message={notice} type="warning" showIcon closable /> : null
    )
}

export default RedhareNotice
