import { useMutation, UseMutationOptions, UseMutationResult, MutationFunction } from 'react-query'
import { message as antdMessage } from 'antd'

function useErrorMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'onError'>,
  errorAlert = true,
  bindErrorBoundary = true,
): UseMutationResult<TData | null, TError, TVariables, TContext> {
  let optionsWithError: UseMutationOptions<TData, TError, TVariables, TContext>
  if (options) {
    optionsWithError = { ...options, useErrorBoundary: true }
  } else {
    optionsWithError = { useErrorBoundary: true }
  }
  return useMutation(async (variables: TVariables) => {
    try {
      return await mutationFn(variables)
    } catch (err) {
      if (err.status !== 403 && err.status !== 404) {
        errorAlert && antdMessage.error(err.message)
        return err
      }
      if (!bindErrorBoundary) return err
      throw err
    }
  }, optionsWithError)
}

export default useErrorMutation
