import { Space, Dropdown, Menu, MenuProps, Button, Input, List, Radio, Modal, message, Table, Select } from 'antd'
import { FC, useCallback, useState } from 'react'
import { AppstoreAddOutlined, CheckCircleFilled, CloseCircleFilled, CloudUploadOutlined, PlusOutlined } from '@ant-design/icons'
import { useProjectContext } from '../../index'
import { getPageList, IPage, PublishStatus } from 'src/api'
import PageCard, { IPageCardProps } from '../PageCard'
import PageEdit, { CreateTypeEnum, EditTypeEnum } from '../PageEdit'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'
import Loading from 'src/components/Loading'
import { OWNER_OPTIONS, PUBLISH_STATUS_OPTIONS } from '../../constants/options'
import Order, { OrderType } from 'src/components/Order'
import { DEFAULT_REQ_PROJECT_MAX_SIZE, DEFAULT_PAGE_NUMBER } from 'src/constants'
import { ColumnCondition, Condition, encodeQueryFilter } from 'src/helpers/queryFilter'
import { isNil } from 'lodash'
import { IProjectPublishResult, PagePublishEnvEnum, publishAll } from 'src/api'
import styles from './style.scss'

const ASC_TOOLTIP = '创建时间升序'
const DESC_TOOLTIP = '创建时间降序'

const PageList: FC = () => {
  const { id } = useProjectContext()
  const [visible, setVisible] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [batchPublishModalVisible, setBatchPublishModalVisible] = useState(false)
  const [batchPublishing, setBatchPublishing] = useState(false)
  const [batchPublishResult, setBatchPublishResult] = useState<IProjectPublishResult>()

  const [publishStatus, setPublishStatus] = useState<PublishStatus | undefined>(undefined)
  const [mineOnly, setMineOnly] = useState<boolean>(false)
  const [orderType, setOrderType] = useState<OrderType>(OrderType.DESC)

  const {
    data: pageListData,
    isLoading,
    refetch: refetchPageList,
  } = useErrorQuery(
    ['getPageList', id, keyword, publishStatus, mineOnly, orderType],
    () =>
      getPageList({
        projectId: id,
        current: DEFAULT_PAGE_NUMBER,
        pageSize: DEFAULT_REQ_PROJECT_MAX_SIZE,
        filter: encodeQueryFilter({
          joiner: 'AND',
          conditions: [
            {
              column: 'projectId',
              operator: '=',
              value: Number(id),
            },
            isNil(publishStatus)
              ? undefined
              : {
                column: 'publishStatus',
                operator: '=',
                value: publishStatus,
              },
            keyword
              ? {
                column: 'name',
                operator: 'LIKE',
                value: keyword,
              }
              : undefined,
          ].filter(Boolean) as (Condition | ColumnCondition)[],
        }),
        ...(mineOnly ? { userEmail: '' } : {}),
        orderType,
      }),
    {},
  )

  const handleRefresh: IPageCardProps['onRefresh'] = useCallback(() => {
    refetchPageList()
  }, [refetchPageList])

  const handleCreateMenuClick: MenuProps['onClick'] = useCallback((_menuInfo) => {
    /*
     * const { key } = menuInfo
     * console.log('handleCreateMenuClick', key)
     */
    setVisible(true)
  }, [])

  const handleBatchPublish = async (env: PagePublishEnvEnum) => {
    setBatchPublishing(true)
    setBatchPublishModalVisible(true)
    try {
      const result = await publishAll({
        projectId: id,
        changelog: `批量发布到${env === PagePublishEnvEnum.live ? '正式' : '测试'}环境`,
        env,
      })
      setBatchPublishResult(result)
    } catch (err) { }
    setBatchPublishing(false)
  }

  const handleCloseBatchPublish = () => {
    if (batchPublishing) {
      message.warn('发布中，请勿关闭弹窗！')
      return
    }
    setBatchPublishModalVisible(false)
  }

  const handleSearch = useCallback((keyword) => setKeyword(keyword), [])

  const handleEditOk = useCallback(() => {
    setVisible(false)
    handleRefresh()
  }, [handleRefresh])

  const handleEditCancel = useCallback(() => {
    setVisible(false)
  }, [])

  const handlePublishStatusChange = (e) => {
    setPublishStatus(e.target.value)
  }

  const handleOnlyMineChange = (e) => {
    setMineOnly(e.target.value)
  }

  const handleCurrentOrderTypeChange = (value: OrderType) => {
    setOrderType(value)
  }

  return (
    <>
      <div className={styles.header}>
        <h3>页面列表</h3>
        <div className={styles.operation}>
          <Space>
            <Input.Search
              placeholder="页面名称"
              onSearch={handleSearch}
              style={{ width: 200 }}
            />
            <Radio.Group
              options={OWNER_OPTIONS}
              onChange={handleOnlyMineChange}
              value={mineOnly}
              optionType="button"
              buttonStyle="solid"
            />
            <Select
              options={PUBLISH_STATUS_OPTIONS}
              onChange={handlePublishStatusChange}
              value={publishStatus}
              placeholder="发布状态"
            />
            <Order
              current={orderType}
              onChange={handleCurrentOrderTypeChange}
              ascTooltip={ASC_TOOLTIP}
              descTooltip={DESC_TOOLTIP}
            />
            <Dropdown
              overlay={
                <Menu
                  onClick={handleCreateMenuClick}
                  items={[
                    {
                      label: '创建空页面',
                      key: CreateTypeEnum.blank,
                      icon: <AppstoreAddOutlined />,
                    },
                    {
                      label: '从模板创建',
                      key: CreateTypeEnum.template,
                      icon: <AppstoreAddOutlined />,
                      disabled: true,
                    },
                  ]}
                />
              }
            >
              <Button type="primary" icon={<PlusOutlined />}>
                创建新页面
              </Button>
            </Dropdown>
            <Dropdown
              overlay={
                <Menu
                  onClick={(menuInfo) => handleBatchPublish(menuInfo.key as PagePublishEnvEnum)}
                  items={[
                    {
                      label: '发布测试环境',
                      key: PagePublishEnvEnum.test,
                    },
                    {
                      label: '发布正式环境',
                      key: PagePublishEnvEnum.live,
                    },
                  ]}
                />
              }
            >
              <Button icon={<CloudUploadOutlined />}>
                发布所有页面
              </Button>
            </Dropdown>
          </Space>
        </div>
      </div>
      <Loading spinning={isLoading} renderChildrenWhenLoading={false}>
        <List<IPage>
          dataSource={pageListData?.records ?? []}
          className={styles.pageCardList}
          renderItem={(pageDataItem) => (
            <PageCard key={pageDataItem.id} page={pageDataItem} onRefresh={handleRefresh} />
          )}
        />
      </Loading>
      <PageEdit
        visible={visible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        title="创建页面"
        type={EditTypeEnum.create}
      />
      <Modal
        visible={batchPublishModalVisible}
        title="批量发布页面"
        onCancel={handleCloseBatchPublish}
        onOk={handleCloseBatchPublish}
        okButtonProps={{ loading: batchPublishing }}
        cancelButtonProps={{ loading: batchPublishing }}>
        <Table
          dataSource={batchPublishResult?.results}
          columns={[
            { title: '页面ID', dataIndex: 'id', },
            { title: '页面名称', dataIndex: 'name', },
            {
              dataIndex: 'success',
              title: '发布结果',
              render: (success: boolean) =>
                success ? <span><CheckCircleFilled style={{ color: '#52c41a' }} /> 成功</span> :
                  <span><CloseCircleFilled style={{ color: '#ff4d4f' }} /> 失败</span>
            },
            { title: '错误信息', dataIndex: 'message' },
          ]}
          loading={batchPublishing}
        />
      </Modal>
    </>
  )
}

export default PageList
