import { ROUTES } from 'src/constants/routes'

export const UPLOAD_URI = 'https://api.redhare.cc/cos/uploadFileFromBrowser'

export const EVENT = {
  NAMESPACE: {
    COMMON: 'common',
  },
  RELOAD_COMPONENT_TEMPLATE: 'refresh-component-templates',
  LOAD_SCHEMA: 'load-schema',
  GET_SCHEMA: 'get-schema',
  DISPATCH_SCHEMA: 'dispatch-schema',
}

export const DEFAULT_PROJECT_PICTURE = [
  'https://cdn.redhare.cc/rs/external/customer/1698/beach-1110498_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/book-794978_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/camera-303650_640.png',
  'https://cdn.redhare.cc/rs/external/customer/1698/camp-4363073_640.png',
  'https://cdn.redhare.cc/rs/external/customer/1698/cats-7122943_640.png',
  'https://cdn.redhare.cc/rs/external/customer/1698/earth-1756274_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/eclipse-1492818_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/fantasy-1077863_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/film-38241_640.png',
  'https://cdn.redhare.cc/rs/external/customer/1698/girl-667936_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/painting-3995999_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/river-8286407_640.png',
  'https://cdn.redhare.cc/rs/external/customer/1698/storytelling-4203628_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/sunset-1651426_640.jpg',
  'https://cdn.redhare.cc/rs/external/customer/1698/superhero-534120_640.jpg',
  'https://p4.itc.cn/q_70/images01/20210518/8072ceb1a1d04f61890db79121ab07e1.jpeg',
]

export const DEFAULT_PROFILE_PICTURE =
  'https://cdn.redhare.cc/rs/external/customer/1698/default-profile.jpeg'

export const DEFAULT_USER_PORTRAIT =
  'https://cdn.redhare.cc/rs/external/customer/1698/default-profile.jpeg'

export const WECHAT_QRCODE = 'https://cdn.redhare.cc/rs/external/customer/1703/WechatIMG10.jpg'

export const TOTAL_USERS_LOGO = 'https://cdn.redhare.cc/rs/external/customer/1703/member.png'

export const TOTAL_PROJECTS_LOGO =
  'https://cdn.redhare.cc/rs/external/customer/1703/project_Logo.png'

export const TOTAL_PUBLISH_LOGO = 'https://cdn.redhare.cc/rs/external/customer/1703/launch.avif'

export const AVG_PV_LOGO = 'https://cdn.redhare.cc/rs/external/customer/1703/pv_logo.jpg'

export const GET_DEFAULT_APP_AVATAR = () => {
  return DEFAULT_PROJECT_PICTURE[Math.floor(Math.random() * DEFAULT_PROJECT_PICTURE.length)]
}

export const CONTENT_ALL_WIDTH = [ROUTES.WORKBENCH, ROUTES.PREVIEW, ROUTES.PREVIEW_PORTAL] // 是否需要内容区不显示最大宽度，如：编辑页不需要限制宽度

export const SIDER_HIDDEN = [ROUTES.WORKBENCH, ROUTES.PREVIEW, ROUTES.PREVIEW_PORTAL]

// 项目角色分类
export const PROJECT_AUTHORITY_TYPE = [
  {
    label: '创建者',
    value: 'redhare.owner',
  },
  {
    label: '管理员',
    value: 'redhare.master',
  },
  {
    label: '维护人员',
    value: 'redhare.developer',
  },
  {
    label: '访客',
    value: 'redhare.visitor',
  },
]

export const PROJECT_AUTHORITY_DEFAULT_TYPE_INDEX = 2

export const DEFAULT_REQ_PROJECT_MAX_SIZE = 100

export const DEFAULT_PAGE_SIZE = 10

export const DEFAULT_PAGE_NUMBER = 1

export const DATA_CENTER_PROJECT_COUNT_DAYS = 100

export const DATA_CENTER_RECENTLY_PUBLISH_COUNT = 50

export const WILDCARD = 'wildcard'

export enum INSTANCE_TYPE {
  PAGE = 'page',
  PORTAL = 'portal',
}

export enum REQ_STATUS {
  MISSING_PARAMTER = 1010001,
  SUCCESS = 0,
}

export enum REQ_URI {
  USER_AUTH = 'user/auth',
  USER_LOGOUT = 'common/user/logout',
  IS_SUPER = 'common/is-super',
  GET_NOTICE = 'common/notice',
  PROJECT_AUTHORIZE = 'project/authorize',
  GET_PROJECT_USERS = 'project/users',
  GET_DATA_CENTER_DATA = 'statistics/data-center-data',
  CHAT_GPT_BASE_URL = 'openai',
}

export enum REQUEST_STATUS {
  UNAUTHORED = 401,
}

export enum ENV_ENUM {
  DEV = 'dev',
  TEST = 'test',
  LIVE = 'live',
}

export enum DATA_SOURCE_TYPE {
  FETCH = 'test',
  RAP = 'rap',
}

export const camelCaseRegexp = /^[a-z]+[A-Za-z0-9]*$/
export const HTTPS_URL_REGEXP = /^(http(s)?:)?\/\/(?:\w+-*\w+\.\w+)/
export const ABSOLUTE_URL_REGEXP = /^(\/\w+-*)+/