import { PagePublishStatusEnum } from 'src/api'
import fetch from 'src/helpers/fetch'
import { IPagingParams, IPagingRes } from 'src/types/custom'

export enum PublishStatus {
	Blank = -1,
	NotPublished = 0,
	PublishedToTest = 1,
	PublishedToLive = 2,
}
/**
 * Page CRUD
 */
export interface IPage {
	id: string
	name: string
	description: string
	projectId: string
	materialIds: string[]
	publishStatus: PagePublishStatusEnum
	createdAt: number
	createdBy: string
	updatedAt: number
	updatedBy: string
}
export interface IPageInfo {
	name: string
	description: string
	projectId: string
	materialIds: string[]
}

export const getPageList = async (
	data: IPagingParams & { userEmail?: string; projectId?: string }
) =>
	fetch<IPagingRes<IPage>>({
		method: 'GET',
		resource: 'page/list',
		params: data,
	})

export const addPage = async (data: IPageInfo) =>
	fetch({
		method: 'POST',
		resource: 'page',
		payload: data,
	})

export const getPage = async (id: string, projectId?: string) =>
	fetch<IPage>({ method: 'GET', resource: `page/${id}/${projectId}` })

export const getPortal = async (id: string) =>
	fetch<IPage>({ method: 'GET', resource: `portal/${id}` })

export const patchPage = async (data: IPage) =>
	fetch({
		method: 'PUT',
		resource: `page/${data.id}`,
		payload: data,
	})

export const delPage = async (id: string) =>
	fetch({ method: 'DELETE', resource: `page/${id}` })

/**
 * Page Schema Management
 */

export const copyPage = async (data: IPageInfo) =>
	fetch({
		method: 'POST',
		resource: 'page/:copy',
		payload: data,
	})
