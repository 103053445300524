import fetch from 'src/helpers/fetch'
import { PageType } from './project'

export interface ISystemMaterial {
  id: string
  name: string
  version: string
  type: PageType
}

export type ISystemMaterialModel = Omit<ISystemMaterial, 'id'>

export type IMaterial = ISystemMaterialModel & { isCustom?: boolean }

export async function getSystemMaterials() {
  const { records } = await fetch<{ records: ISystemMaterial[]; total: number }>({
    resource: '/material/list',
    method: 'GET',
  })
  return records
}

export function getMaterialVersions(name: string) {
  return fetch<string[] | string>({
    resource: '/material/npm/versions',
    method: 'GET',
    params: { name },
  })
}

export function addSystemMaterial(model: ISystemMaterialModel) {
  return fetch<ISystemMaterial>({
    resource: '/material',
    method: 'POST',
    payload: model,
  })
}

export function updateSystemMaterial(material: ISystemMaterial) {
  return fetch<ISystemMaterial>({
    resource: `/material/${material.id}`,
    method: 'PUT',
    payload: material,
  })
}

export function deleteSystemMaterial(material: ISystemMaterial) {
  return fetch<boolean>({
    resource: `/material/${material.id}`,
    method: 'DELETE',
  })
}
