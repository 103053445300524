import { PublishStatus } from 'src/api'

export const PUBLISH_STATUS_OPTIONS = [
	{
		label: '所有',
		value: undefined,
	},
	{
		label: '未发布',
		value: PublishStatus.NotPublished,
	},
	{
		label: '发布测试',
		value: PublishStatus.PublishedToTest,
	},
	{
		label: '发布正式',
		value: PublishStatus.PublishedToTest,
	},
]

export const OWNER_OPTIONS = [
	{
		label: '所有',
		value: false,
	},
	{
		label: '我的',
		value: true,
	},
]
