import {
  IPublicTypeCompositeValue,
  IPublicTypeJSExpression,
  IPublicTypeJSFunction,
  InterpretDataSourceConfig,
} from '@redhare/lowcode-types'
import fetch from 'src/helpers/fetch'

export interface ILowCodeState {
  [key: string]: IPublicTypeCompositeValue
}

export interface ILowCodeMethod {
  [key: string]: IPublicTypeJSExpression | IPublicTypeJSFunction
}

export interface IComponentTemplateBindings {
  states: ILowCodeState
  methods: ILowCodeMethod
  dataSources: InterpretDataSourceConfig[]
}

export interface IComponentTemplate {
  id: string
  name: string
  description?: string
  previewImageUrl?: string
  group?: string
  schema: any
  bindings: IComponentTemplateBindings
  sharedWith?: string[]
  createdBy: string
  isPublic: boolean
}

export type IComponentTemplateModel = IComponentTemplate & {
  includeDataSource?: boolean
}

export const getPrivateComponentTemplates = (
  current?: number,
  keyword?: string,
  includingShared = true,
  pageSize = 100,
) =>
  fetch<{ records: IComponentTemplate[]; total: number }>({
    method: 'GET',
    resource: 'component-template/private',
    params: {
      current,
      includingShared,
      pageSize,
      keyword,
    },
  })

export const getPublicComponentTemplates = (current?: number, keyword?: string, pageSize = 100) =>
  fetch<{ records: IComponentTemplate[]; total: number }>({
    method: 'GET',
    resource: 'component-template/public',
    params: {
      keyword,
      pageSize,
      current,
    },
  })

export const saveComponentTemplate = (template: IComponentTemplate) =>
  fetch<IComponentTemplate>({
    method: 'POST',
    resource: 'component-template',
    payload: template,
  })

export const updateComponentTemplate = (template: IComponentTemplate) =>
  fetch<IComponentTemplate>({
    method: 'PUT',
    resource: `component-template/${template.id}`,
    payload: template,
  })

export const deleteComponentTemplate = (id: string) =>
  fetch<boolean>({
    method: 'DELETE',
    resource: `component-template/${id}`,
  })

export const publishPrivateComponentTemplate = (id: string) =>
  fetch<boolean>({
    method: 'POST',
    resource: `component-template/publish/${id}`,
  })
