import styles from './style.scss'
import { useMemo, useState, useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { checkSuperAdminStatus } from 'src/api'
import { CONTENT_ALL_WIDTH, SIDER_HIDDEN } from 'src/constants'
import LeftPanel from './LeftPanel'
import Content from './Content'
import RedhareNotice from './RedhareNotice'
import { Spin } from 'antd'

const Layout = () => {
  const { pathname } = useLocation()
  const [loaded, setLoaded] = useState(false)
  const [isSuper, setIsSuper] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const superStatus: any = await checkSuperAdminStatus()
      setLoaded(true)
      if (superStatus?.code === 0) setIsSuper(true)
    }

    fetchData()
  }, [])
  // 决定是否隐藏侧边栏（sider）
  const isHiddenSider = useMemo(
    () =>
      SIDER_HIDDEN.some((path) => {
        const match = matchPath(pathname, { path, exact: true })
        return Boolean(match)
      }),
    [pathname],
  )

  const isContentAllWidth = useMemo(
    () =>
      CONTENT_ALL_WIDTH.some((path) => {
        const match = matchPath(pathname, { path, exact: true })
        return Boolean(match)
      }),
    [pathname],
  )
  if (!loaded) {
    return <Spin spinning />
  }

  return (
    <div className={styles.root}>
      {isHiddenSider ? undefined : (
        <div className={classnames(styles.left)}>
          <LeftPanel isSuper={isSuper} />
        </div>
      )}

      <div className={classnames(styles.right, { [styles.rightFull]: isHiddenSider })}>
        <RedhareNotice />
        <Content isSuper={isSuper} />
      </div>
    </div>
  )
}

export default Layout
