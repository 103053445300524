import { Button } from 'antd'
import { FC, useCallback, useState } from 'react'
import { getPortalByProjectId } from 'src/api'
import { getProject } from 'src/api'
import Loading from 'src/components/Loading'
import PublishPane from 'src/components/Publish'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'
import { INSTANCE_TYPE } from 'src/constants'
import { ROUTES } from 'src/constants/routes'
import PortalSettings from 'src/pages/project/components/PortalSettings'
import styles from './style.scss'
import { useProjectContext } from '../../index'

export interface IDetailProps {
  info?: string
}

export enum MoreMenuEnum {
  delete = 'delete',
}

const Portal: FC<IDetailProps> = () => {
  const { id } = useProjectContext()
  const initPortal = { projectId: id, name: '', faviconIco: '' }

  const { isFetching: isProjectFetching } = useErrorQuery(['getProject', id], () => getProject(id))

  const { isFetching: isPortalFetching, data: portalData, refetch: refetchPortal } = useErrorQuery(['getPortal', id], () =>
    getPortalByProjectId(id),
  )

  const [portalSettingsVisible, setPortalSettingsVisible] = useState(false)

  const handlePortalSettingsOk = useCallback(() => {
    refetchPortal()
    setPortalSettingsVisible(false)
  }, [refetchPortal])

  const handlePortalSettingsCancel = useCallback(() => {
    setPortalSettingsVisible(false)
  }, [])

  const handleClickEnablePortal = async () => {
    setPortalSettingsVisible(true)
  }

  const handleEditPortal = () => {
    const portalId = portalData?.id
    const url = `/#${ROUTES.WORKBENCH}?id=${portalId}&projectId=${id}&instanceType=${INSTANCE_TYPE.PORTAL}`
    window.open(url, '_blank')
  }
  const handleSettingPortal = () => {
    setPortalSettingsVisible(true)
  }

  const handlePreviewPortal = () => {
    const portalId = portalData?.id
    portalId &&
      window.open(
        `/#${ROUTES.APP_DETAIL}?id=${portalId}`,
        '_blank',
      )
  }

  /**
   * publish
   */
  const [publishVisible, setPublishVisible] = useState(false)
  const handlePublishClose = useCallback(() => {
    setPublishVisible(false)
  }, [])

  return (
    <>
      <Loading spinning={isProjectFetching || isPortalFetching}>
        <div className={styles.portal}>
          {portalData && portalData.uuid ? (
            <div className={styles.portalInfo}>
              <h2>网站：{portalData.name}</h2>
              <div className={styles.buttonArea}>
                <Button onClick={handleEditPortal}>进入</Button>
                <Button onClick={handlePreviewPortal} className={styles.buttonSize}>
                  预览
                </Button>
                <Button onClick={() => setPublishVisible(true)} className={styles.buttonSize}>
                  发布
                </Button>
                <Button onClick={handleSettingPortal} className={styles.buttonSize}>
                  设置
                </Button>
              </div>
            </div>
          ) : (
            <div className={styles.portalInfo}>
              <h3>网站</h3>
              <span style={{ padding: '12px 0' }}>
                网站可以将多个页面整合在一起，使用菜单进行页面导航
              </span>
              <div className={styles.buttonArea}>
                <Button onClick={handleClickEnablePortal}>开启网站功能</Button>
              </div>
            </div>
          )}
        </div>
      </Loading>

      {portalData?.id && (
        <PublishPane
          instanceId={`${portalData.id}`}
          projectId={id}
          instanceType={INSTANCE_TYPE.PORTAL}
          visible={publishVisible}
          title={`发布 “${portalData?.name}”`}
          onClose={handlePublishClose}
        />
      )}

      <PortalSettings
        visible={portalSettingsVisible}
        onOk={handlePortalSettingsOk}
        onCancel={handlePortalSettingsCancel}
        title="网站设置"
        data={portalData?.uuid ? portalData : initPortal}
      />
    </>
  )
}

export default Portal
