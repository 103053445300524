import { Space, Button, Table, Modal, Drawer, message } from 'antd'
import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons'
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table'
import {
  PagePublishEnvEnum,
  IPagePublish,
  getPagePublishList,
  getPortalPublishList,
} from 'src/api'
import { addPageSchema, addPortalSchema, getSchemaById } from 'src/api'
import { format } from 'src/helpers/time'
import { FC, useCallback, useMemo, useState } from 'react'
import { noop } from 'src/helpers/function'
import useErrorQuery from 'src/hooks/useReactQuery/useErrorQuery'
import classNames from 'classnames'
import { useMutation } from 'react-query'
import { INSTANCE_TYPE } from 'src/constants'
import { ROUTES } from 'src/constants/routes'

import { AnyFunc, IPagingParams } from 'src/types/custom'

export interface IHistoryTableProps {
  env: PagePublishEnvEnum
  instanceId: string
  projectId: string
  className?: string
  onRevert?: AnyFunc
  onClose?: AnyFunc
  revertGuide?: boolean
  instanceType: INSTANCE_TYPE
}

const HistoryTable: FC<IHistoryTableProps> = ({
  env,
  instanceId,
  projectId,
  className,
  instanceType,
  onClose = noop,
  onRevert = noop,
  revertGuide = true,
}) => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  })
  const [previewVisible, setPreviewVisible] = useState(false)

  const fetchFn = instanceType === INSTANCE_TYPE.PORTAL ? getPortalPublishList : getPagePublishList
  const { data, isLoading } = useErrorQuery(
    ['getPagePublishList', pagination, env, instanceId],
    () =>
      fetchFn({
        pageSize: pagination.pageSize,
        current: pagination.current,
        pageId: instanceId,
        projectId: projectId,
        env,
      }),
    {
      keepPreviousData: true,
      staleTime: 1000,
      refetchOnWindowFocus: false,
      onSuccess(data) {
        const { total = 0 } = data ?? {}
        setPagination({
          ...pagination,
          total,
        })
      },
    },
  )

  const handleTableChange = (newPagination: IPagingParams) => {
    setPagination(newPagination)
  }

  const handlePreview = useCallback(async (record: IPagePublish) => {
    const previewUrl = `/#${ROUTES.PREVIEW}?id=${record.id}&versionId=${record.schemaId}`
    window.open(previewUrl, '_blank')
  }, [])

  const { mutateAsync: mutateAddPageSchema } = useMutation(async (schemaId: string) => {
    const isPortal = instanceType === INSTANCE_TYPE.PORTAL
    const addSchemaFunc = isPortal ? addPortalSchema : addPageSchema
    const pageSchema = await getSchemaById(schemaId)
    pageSchema.projectId = projectId

    if (isPortal) {
      pageSchema.portalId = instanceId
    } else {
      pageSchema.pageId = instanceId
    }
    return addSchemaFunc(pageSchema)
  })

  const showRevertGuide = useCallback(() => {
    if (!revertGuide) return
    Modal.confirm({
      icon: <CheckCircleOutlined />,
      okText: '继续编辑',
      onOk: async () => {
        onClose?.()
      },
      cancelText: '取消',
      content: '恢复完成!',
    })
  }, [revertGuide, onClose])

  const handleRevert = useCallback(
    (schemaId: string) => {
      Modal.confirm({
        icon: <WarningOutlined />,
        title: '将此版本恢复到工作区？',
        okText: "确认",
        cancelText: "取消",
        onOk: async () => {
          try {
            await mutateAddPageSchema(schemaId)
            onRevert()
            showRevertGuide()

          } catch (ex) {
            message.error('版本恢复失败！')
            console.error('版本恢复失败！失败原因：', ex)
          }
        },
      })
    },
    [mutateAddPageSchema, onRevert, showRevertGuide],
  )

  const columns: ColumnsType<IPagePublish> = useMemo(
    () => [
      {
        title: '更新日志',
        dataIndex: 'changelog',
        key: 'changelog',
        width: 320,
      },
      {
        title: '发布人',
        dataIndex: 'createdBy',
        key: 'createdBy',
      },
      {
        title: '发布时间',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => format(record.createdAt),
      },
      {
        title: '操作',
        key: 'action',
        render: (_, record) => (
          <Space size="middle">
            <Button onClick={() => handleRevert(record.schemaId)}>恢复此版本</Button>
            <Button onClick={() => handlePreview(record)}>预览此版本</Button>
          </Space>
        ),
      },
    ],
    [handlePreview, handleRevert],
  )

  return (
    <>
      <Table
        className={classNames(className)}
        columns={columns}
        rowKey="createdAt"
        dataSource={data?.records ?? []}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        scroll={{ y: 400 }}
      />
      <Drawer
        width="80%"
        title="Preview"
        placement="right"
        visible={previewVisible}
        onClose={() => setPreviewVisible(false)}
        destroyOnClose
      >
      </Drawer>
    </>
  )
}

export default HistoryTable
