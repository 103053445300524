import styles from './style.scss'
import { Button, Divider, Drawer, Form, Input, Select, Space, Switch } from 'antd'
import { useContext, useEffect, useMemo, useState } from 'react'
import MonacoEditor from '@alilc/lowcode-plugin-base-monaco-editor'
import { HttpMethod, IAppDataSource, IAppDataSourceInterface } from 'src/api'
import { BugOutlined, PlaySquareFilled } from '@ant-design/icons'
import { handlerValidator, testInterface } from './utils'
import { DataSourceContext } from './context'
import { ABSOLUTE_URL_REGEXP, HTTPS_URL_REGEXP, camelCaseRegexp } from 'src/constants'
import { DEFAULT_INTERCEPTOR } from './const'

interface IInterfaceDrawerProps {
  visible: boolean
  currentInterfaceIndex: number
  currentDataSource: IAppDataSource
  mode: 'edit' | 'view'
  isAdd: boolean
  onOk: (dsInterface: IAppDataSourceInterface) => void
  onCancel: () => void
}

const HTTP_METHOD_OPTIONS = Object.entries(HttpMethod).map(([key, val]) => ({
  label: key,
  value: val,
}))

export const InterfaceDrawer = (props: IInterfaceDrawerProps) => {
  const { visible, currentInterfaceIndex, currentDataSource, mode, isAdd, onOk, onCancel } = props
  const { dataSource, dataSourceList } = useContext(DataSourceContext)
  const [form] = Form.useForm<IAppDataSourceInterface>()
  const [willFetchEnabled, setWillFetchEnabled] = useState(false)
  const [dataHandlerEnabled, setDataHandlerEnabled] = useState(false)
  const [errorHandlerEnabled, setErrorHandlerEnabled] = useState(false)
  const [params, setParams] = useState('{}')
  const [result, setResult] = useState('')
  const [exception, setException] = useState('')

  const title = useMemo(() => {
    if (mode === 'view') {
      return '查看接口'
    } else if (isAdd) {
      return '添加接口'
    } else {
      return '编辑接口'
    }
  }, [mode, isAdd])

  const handleOk = async () => {
    try {
      const dsInterface = await form.validateFields()
      onOk(dsInterface)
    } catch (ex) {
      console.error(ex)
    }
  }

  const handleTest = async () => {
    setResult('')
    setException('')
    const currentInterface = await form.validateFields()
    try {
      const result = await testInterface(dataSource, currentInterface, params)
      setResult(JSON.stringify(result, null, 2))
    } catch (ex) {
      setException((ex as Error).message)
    }
  }

  useEffect(() => {
    form.resetFields()
    if (currentInterfaceIndex > -1) {
      const currentInterface = currentDataSource.interfaces[currentInterfaceIndex]
      form.setFieldsValue(currentInterface)
      setWillFetchEnabled(currentInterface?.willFetchEnabled)
      setDataHandlerEnabled(currentInterface?.dataHandlerEnabled)
      setErrorHandlerEnabled(currentInterface?.errorHandlerEnabled)
    } else {
      setWillFetchEnabled(false)
      setDataHandlerEnabled(false)
      setErrorHandlerEnabled(false)
    }
  }, [form, currentInterfaceIndex, currentDataSource])

  return (
    <Drawer
      width="1200px"
      visible={visible}
      title={title}
      onClose={() => onCancel()}
      keyboard={false}
      maskClosable={false}
      footer={
        <Space>
          <Button onClick={() => handleOk()} type="primary">
            保存
          </Button>
          <Button onClick={() => onCancel()}>关闭</Button>
        </Space>
      }
    >
      <div className={styles.interface}>
        <Form form={form} layout="vertical" className={styles.setting}>
          <Form.Item
            label="接口ID"
            tooltip="在页面中作为数据源ID"
            name="id"
            rules={[
              { required: true, message: '必填' },
              {
                validator(rule, value: string, callback) {
                  if (camelCaseRegexp.test(value)) {
                    callback()
                  } else {
                    callback('请使用camelCase变量名作为ID')
                  }
                },
              },
              {
                validator(rule, value: string, callback) {
                  const hasSameName = dataSourceList.some((ds) =>
                    ds.interfaces.some(
                      (currentInterface, index) =>
                        index !== currentInterfaceIndex && currentInterface.id === value,
                    ),
                  )
                  if (hasSameName) {
                    callback(`ID为${value}的接口已存在！`)
                  } else {
                    callback()
                  }
                },
              },
            ]}
            required
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="接口地址"
            name="uri"
            rules={[
              { required: true, message: '必填' },
              {
                validator(rule, value, callback) {
                  if (!value || HTTPS_URL_REGEXP.test(value) || ABSOLUTE_URL_REGEXP.test(value)) {
                    callback()
                  } else {
                    callback('URI格式错误！')
                  }
                },
              },
            ]}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="请求方式"
            name="method"
            rules={[{ required: true, message: '必填' }]}
            initialValue={HttpMethod.Get}
            required
          >
            <Select options={HTTP_METHOD_OPTIONS} />
          </Form.Item>
          <Form.Item label="接口独享拦截器">
            <Form.Item noStyle name="willFetchEnabled" />
            <Form.Item
              label={
                <Space>
                  <Switch
                    size="small"
                    checked={willFetchEnabled}
                    onChange={() => {
                      setWillFetchEnabled(!willFetchEnabled)
                      form.setFieldValue('willFetchEnabled', !willFetchEnabled)
                    }}
                  />
                  <span>请求拦截器</span>
                </Space>
              }
              name="willFetch"
              initialValue={DEFAULT_INTERCEPTOR.WILL_FETCH}
              rules={[
                { required: willFetchEnabled, message: '必填！' },
                {
                  validator(rule, value, callback) {
                    if (value) {
                      if (handlerValidator(value)) {
                        callback()
                      } else {
                        callback('拦截器不合法！')
                      }
                    } else {
                      callback()
                    }
                  },
                },
              ]}
            >
              {willFetchEnabled ? (
                <MonacoEditor
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  defaultValue={DEFAULT_INTERCEPTOR.WILL_FETCH}
                />
              ) : (
                ''
              )}
            </Form.Item>

            <Form.Item
              label={
                <Space>
                  <Switch
                    size="small"
                    checked={dataHandlerEnabled}
                    onChange={() => {
                      setDataHandlerEnabled(!dataHandlerEnabled)
                      form.setFieldValue('dataHandlerEnabled', !dataHandlerEnabled)
                    }}
                  />
                  <span>结果拦截器</span>
                </Space>
              }
              name="dataHandler"
              initialValue={DEFAULT_INTERCEPTOR.DATA_HANDLER}
              rules={[
                { required: dataHandlerEnabled, message: '必填！' },
                {
                  validator(rule, value, callback) {
                    if (value) {
                      if (handlerValidator(value)) {
                        callback()
                      } else {
                        callback('拦截器不合法！')
                      }
                    } else {
                      callback()
                    }
                  },
                },
              ]}
            >
              {dataHandlerEnabled ? (
                <MonacoEditor
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  defaultValue={DEFAULT_INTERCEPTOR.DATA_HANDLER}
                />
              ) : (
                ''
              )}
            </Form.Item>
            <Form.Item
              label={
                <Space>
                  <Switch
                    size="small"
                    checked={errorHandlerEnabled}
                    onChange={() => {
                      setErrorHandlerEnabled(!errorHandlerEnabled)
                      form.setFieldValue('errorHandlerEnabled', !errorHandlerEnabled)
                    }}
                  />
                  <span>异常拦截器</span>
                </Space>
              }
              name="errorHandler"
              initialValue={DEFAULT_INTERCEPTOR.ERROR_HANDLER}
              rules={[
                { required: errorHandlerEnabled, message: '必填！' },
                {
                  validator(rule, value, callback) {
                    if (value) {
                      if (handlerValidator(value)) {
                        callback()
                      } else {
                        callback('拦截器不合法！')
                      }
                    } else {
                      callback()
                    }
                  },
                },
              ]}
            >
              {errorHandlerEnabled ? (
                <MonacoEditor
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  defaultValue={DEFAULT_INTERCEPTOR.ERROR_HANDLER}
                />
              ) : (
                ''
              )}
            </Form.Item>
          </Form.Item>
        </Form>
        <div className={styles.test}>
          <Space className={styles.testTitle} align="baseline">
            <BugOutlined />
            <span>测试接口</span>
            <div className={styles.testTip}>（建议打开chrome调试工具）</div>
          </Space>
          <Divider>参数</Divider>
          <MonacoEditor
            value={params}
            onChange={(val) => setParams(val)}
            language="javascript"
            style={{ border: '1px solid #ccc' }}
          />
          <Divider>
            <Button type="primary" icon={<PlaySquareFilled />} onClick={handleTest}>
              测试接口
            </Button>
          </Divider>
          {exception ? (
            <div>
              <div>异常：</div>
              <div className={styles.exception}>{exception}</div>
            </div>
          ) : (
            <div>
              <div>结果：</div>
              <MonacoEditor
                className={styles.result}
                value={result}
                language="json"
                options={{ readOnly: true }}
              />
            </div>
          )}
        </div>
      </div>
    </Drawer>
  )
}
