import styles from './style.scss'
import { useState } from 'react'
import { Button, Drawer, Empty } from 'antd'
import { ApiOutlined, PlusOutlined } from '@ant-design/icons'
import { useProjectContext } from '../..'
import { InterfaceGroupDrawer } from './interface-group-drawer'
import { IAppDataSource, patchProject } from 'src/api'
import { DataSourceContext } from './context'
import { InterfaceGroupCard } from './interface-group-card'

export const AppInterface = () => {
  const { project, refresh } = useProjectContext()
  const dataSourceList = project?.dataSource?.list || []
  const [visible, setVisible] = useState(false)
  const [dataSourceModalVisible, setDataSourceModalVisible] = useState(false)
  const [currentDataSource, setCurrentDataSource] = useState<IAppDataSource>()
  const [mode, setMode] = useState<'edit' | 'view'>('view')
  const [isAdd, setIsAdd] = useState(false)

  const handleAdd = () => {
    const currentDataSource: IAppDataSource = {
      id: crypto.randomUUID(),
      name: '',
      interfaces: [],
    }
    setCurrentDataSource(currentDataSource)
    setDataSourceModalVisible(true)
    setIsAdd(true)
    setMode('edit')
  }

  const handleSave = async (ds: IAppDataSource) => {
    if (isAdd) {
      dataSourceList.push(ds)
    } else if (mode === 'edit') {
      const index = dataSourceList.findIndex((item) => item.id === ds.id)
      dataSourceList.splice(index, 1, ds)
    }
    debugger
    project.dataSource = {
      version: crypto.randomUUID(),
      list: dataSourceList.slice(),
    }
    await patchProject(project)
    await refresh()
    setDataSourceModalVisible(false)
  }

  const handleView = (dataSource: IAppDataSource) => {
    setCurrentDataSource(dataSource)
    setMode('view')
    setIsAdd(false)
    setDataSourceModalVisible(true)
  }

  const handleDelete = async (dataSource: IAppDataSource) => {
    const index = dataSourceList.findIndex((item) => item.id === dataSource.id)
    dataSourceList.splice(index, 1)
    project.dataSource = {
      version: crypto.randomUUID(),
      list: dataSourceList.slice(),
    }
    await patchProject(project)
    await refresh()
  }

  return (
    <DataSourceContext.Provider
      value={{ dataSourceList, dataSource: currentDataSource, setDataSource: setCurrentDataSource }}
    >
      <Button icon={<ApiOutlined />} onClick={() => setVisible(true)}>
        接口管理
      </Button>
      <Drawer title="接口管理" open={visible} onClose={() => setVisible(false)} width="400px">
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
          添加接口集合
        </Button>
        {dataSourceList.length ? (
          <div className={styles.cardList} key="0">
            {dataSourceList.map((ds) => (
              <InterfaceGroupCard
                key={ds.id}
                dataSource={ds}
                onClick={(ds) => handleView(ds)}
                onDelete={(ds) => handleDelete(ds)}
              />
            ))}
          </div>
        ) : (
          <Empty></Empty>
        )}

        <InterfaceGroupDrawer
          onChangeMode={(mode) => setMode(mode)}
          visible={dataSourceModalVisible}
          onOk={(ds) => handleSave(ds)}
          mode={mode}
          onCancel={() => setDataSourceModalVisible(false)}
        />
      </Drawer>
    </DataSourceContext.Provider>
  )
}
