import { FC, useCallback, useRef, useState } from 'react'
import { Input } from 'antd'

import { getProjectList, IProject } from 'src/api'
import InfiniteScrollList from 'src/components/InfiniteScrollList'
import { DEFAULT_REQ_PROJECT_MAX_SIZE } from 'src/constants'
import AppCard from './components/AppCard'
import CombineSearchResult from './components/CombineSearchResult'
import AppCreation from './components/AppCreation'
import styles from './style.scss'
export interface IFilterData {
  keyword: string
}

const Project: FC = () => {
  /** Used to refresh the list */
  const [refreshFlag, setRefreshFlag] = useState(0)
  const filterData = useRef<IFilterData>({ keyword: '' })
  const handleRefresh = useCallback(() => {
    setRefreshFlag((refreshFlag + 1) % 2)
  }, [refreshFlag])

  const handleFilterChange = useCallback(
    (data) => {
      filterData.current = data
      handleRefresh()
    },
    [handleRefresh],
  )

  const isSearching = !!filterData.current.keyword

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <AppCreation onRefresh={handleFilterChange} />
        <Input.Search
          placeholder="输入应用名称搜索"
          onSearch={(value) => {
            handleFilterChange({ keyword: value })
          }}
          style={{ width: 200, marginLeft: 24 }}
          allowClear
        />
      </div>

      {isSearching ? (
        <CombineSearchResult keyword={filterData?.current?.keyword} />
      ) : (
        <InfiniteScrollList<IProject>
          key={refreshFlag}
          className={styles.projectCardList}
          limit={DEFAULT_REQ_PROJECT_MAX_SIZE}
          renderItem={(project) => (
            <AppCard
              key={project.id}
              project={project}
              onRefresh={handleRefresh}
            />
          )}
          fetcher={(params) => getProjectList({
            ...params,
          })}
        />
      )}
    </div>
  )
}

export default Project
