import { FC, CSSProperties, useCallback, useState } from 'react'
import {
  Card,
  Dropdown,
  Menu,
  MenuProps,
  Space,
  Descriptions,
  Modal,
  Button,
  Badge,
  message,
} from 'antd'
import { EditOutlined, InfoCircleFilled, DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { MdOutlinePermIdentity } from 'react-icons/md'
import { IProject, delProject } from 'src/api'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import { format } from 'src/helpers/time'
import { useMutation } from 'react-query'
import Edit, { EditTypeEnum } from '../Edit'
import Authorize from '../Authorize'
import { PageTypeIcon } from 'src/components/ProjectTypeIcon'
import { AnyFunc } from 'src/types/custom'

import styles from './style.scss'

export interface IProjectCardProps {
  project: IProject
  className?: string
  style?: CSSProperties
  onRefresh?: AnyFunc
}

export enum MoreMenuEnum {
  delete = 'delete',
  edit = 'edit',
  authorize = 'authorize',
}

const AppCard: FC<IProjectCardProps> = ({ project, className, onRefresh }) => {
  const history = useHistory()
  const [editVisible, setEditVisible] = useState(false)
  const [authorizeVisible, setAuthorizeVisible] = useState(false)
  const { mutateAsync: mutateDeleteProject } = useMutation((id: string) => delProject(id))

  // 点击编辑项目
  const handleEditOutlinedClick = useCallback(() => {
    setEditVisible(true)
  }, [])

  // 点击授予他人权限
  const handleAuthorize = useCallback(() => {
    setAuthorizeVisible(true)
  }, [])

  const handleEditOk = useCallback(() => {
    setEditVisible(false)
    onRefresh?.()
  }, [onRefresh])

  const handleAuthorizeOK = useCallback(() => {
    setAuthorizeVisible(false)
  }, [])

  const handleEditCancel = useCallback(() => {
    setEditVisible(false)
  }, [])

  const handleAuthorizeCancel = useCallback(() => {
    setAuthorizeVisible(false)
  }, [])

  const handleClick = useCallback(() => {
    // generatePath
    history.push(`${ROUTES.APP_DETAIL}?id=${project.id}`)
  }, [history, project.id])

  const handleDelete = useCallback(() => {
    Modal.confirm({
      icon: <InfoCircleFilled />,
      title: `确定要删除这个应用吗： "${project.name}"?`,
      onOk: async () => {
        const retDeleted = (await mutateDeleteProject(project.id)) as {
          code: number
          message: string
        }
        if (retDeleted?.code !== 0) {
          message.error(`ERROR:${retDeleted?.message}`)
        } else onRefresh?.()
      },
    })
  }, [mutateDeleteProject, onRefresh, project.id, project.name])

  const handleMoreMenuClick: MenuProps['onClick'] = useCallback(
    (menuInfo) => {
      const { key } = menuInfo
      switch (key) {
        case MoreMenuEnum.delete:
          handleDelete()
          break
        case MoreMenuEnum.edit:
          handleEditOutlinedClick()
          break
        case MoreMenuEnum.authorize:
          handleAuthorize()
          break
        default:
          break
      }
    },
    [handleDelete],
  )

  const CardNode = (
    <Card
      className={styles.card}
      hoverable
      actions={[
        <Dropdown
          key="ellipsis"
          trigger={['hover']}
          overlay={
            <Menu
              onClick={handleMoreMenuClick}
              items={[
                {
                  label: (
                    <Space key="edit">
                      <EditOutlined />
                      编辑
                    </Space>
                  ),
                  key: MoreMenuEnum.edit,
                },
                {
                  label: (
                    <Space key="authorize">
                      <MdOutlinePermIdentity />
                      授权
                    </Space>
                  ),
                  key: MoreMenuEnum.authorize,
                },
                {
                  label: (
                    <Space>
                      <DeleteOutlined /> 删除
                    </Space>
                  ),
                  key: MoreMenuEnum.delete,
                },
              ]}
            />
          }
          placement="bottomRight"
        >
          <Button size="small" type="text">
            <MenuOutlined />
          </Button>
        </Dropdown>,
      ]}
    >
      <div className={styles.cardInfoArea} onClick={handleClick}>
        <img alt="" src={project.picture} className={styles.avatar} />
        <div className={styles.cardInfoRightArea}>
          <div className={styles.cardInfoTitle}>
            <PageTypeIcon type={project.type} />
            <span>{project.name}</span>
          </div>
          <div className={styles.cardInfoDes}>描述：{project.description || '暂无'}</div>
        </div>
      </div>
      <div className={styles.update}>
        用户 {project.updatedBy} 最后编辑于 {format(project.updatedAt)}
      </div>
    </Card>
  )

  const BadgeCard = (
    <Badge.Ribbon text="公共项目" color="purple">
      {CardNode}
    </Badge.Ribbon>
  )

  return (
    <div className={classnames(styles.root, className)}>
      {project.isPublic ? BadgeCard : CardNode}
      {editVisible ? (
        <Edit
          visible={editVisible}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
          title="应用设置"
          type={EditTypeEnum.modify}
          data={project}
        />
      ) : null}
      {authorizeVisible ? (
        <Authorize
          visible={authorizeVisible}
          onOk={handleAuthorizeOK}
          onCancel={handleAuthorizeCancel}
          data={project}
        />
      ) : null}
    </div>
  )
}

export default AppCard
