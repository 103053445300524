import { Button, Card, Modal, Popconfirm } from 'antd'
import { IAppDataSource } from 'src/api'
import styles from './style.scss'
import { DeleteOutlined } from '@ant-design/icons'

interface IInterfaceGroupCard {
  dataSource: IAppDataSource
  onClick: (ds: IAppDataSource) => void
  onDelete: (ds: IAppDataSource) => void
}

export const InterfaceGroupCard = (props: IInterfaceGroupCard) => {
  const { dataSource, onClick, onDelete } = props

  return (
    <Card
      size="small"
      className={styles.card}
      onClick={() => onClick(dataSource)}
      hoverable
      title={dataSource.name}
      extra={
        <div onClick={(e) => e.stopPropagation()}>
          <Popconfirm title="确定要删除该接口集合吗？" onConfirm={() => onDelete(dataSource)}>
            <Button size="small" icon={<DeleteOutlined />} />
          </Popconfirm>
        </div>
      }
    >
      <div>{dataSource.description}</div>
    </Card>
  )
}
