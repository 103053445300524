/* eslint-disable multiline-comment-style */
import { AppstoreAddOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal, ModalProps, Space } from 'antd'
import { FC, useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { enablePortal, IPortalForm, modifyPortalSetting } from 'src/api'
import { AnyFunc } from 'src/types/custom'

import styles from './style.scss'

export interface IPortalSettingsProps {
  visible: boolean
  onOk: AnyFunc
  onCancel: AnyFunc
  title: string
  data?: IPortalForm
}

const DEFAULT_ICO_URL = ''

const PortalSettings: FC<IPortalSettingsProps> = (props) => {
  const { visible, onOk, onCancel, title, data } = props
  const isModify = !!data?.id
  const [form] = Form.useForm<IPortalForm>()

  const setInitialFormVal = useCallback(() => {
    const formData = { ...data }
    form.setFieldsValue(formData)
  }, [data, form])

  const { mutateAsync: mutateEnablePortal, isLoading: isAdding } = useMutation(
    (data: IPortalForm) => enablePortal(data),
  )
  const { mutateAsync: mutateModifyPortalSetting, isLoading: isPatching } = useMutation(
    (data: IPortalForm) => modifyPortalSetting(data),
  )

  const handleChange = useCallback(() => {
    // console.log('handleChange')
  }, [])

  const handleOk: ModalProps['onOk'] = useCallback(async () => {
    try {
      const formData = await form.validateFields()
      formData.projectId = data.projectId
      // todo: add image upload
      formData.faviconIco = DEFAULT_ICO_URL
      if (isModify) {
        await mutateModifyPortalSetting({ ...data, ...formData })
      } else {
        await mutateEnablePortal({ ...formData })
      }
      onOk()
      if (!data) {
        form.resetFields()
      }
    } catch (error) {
      console.error(error)
    }
  }, [form, isModify, onOk, data, mutateModifyPortalSetting, mutateEnablePortal])

  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onCancel()
    setInitialFormVal()
  }, [onCancel, setInitialFormVal])

  const ModalProps = useMemo(
    () => ({
      className: styles.modal,
      title: (
        <Space size="small">
          <AppstoreAddOutlined />
          {title}
        </Space>
      ),
      visible,
      onOk: handleOk,
      onCancel: handleCancel,
      footer: [
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" loading={isAdding || isPatching} onClick={handleOk}>
          保存
        </Button>,
      ],
    }),
    [handleCancel, handleOk, isAdding, isPatching, title, visible],
  )

  return (
    <Modal {...ModalProps} destroyOnClose width={1000}>
      <Form form={form} initialValues={{ ...data }} layout="vertical" onValuesChange={handleChange}>
        <Form.Item label="网站名称" name="name" required>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="网站图标" name="faviconIco">
          <img src={DEFAULT_ICO_URL} alt="favicon.ico" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PortalSettings
