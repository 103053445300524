import { Button, Drawer, Form, Input, Space } from 'antd'
import { useContext, useEffect } from 'react'
import { SemVer } from 'semver'
import { IAppDep } from 'src/api'
import { HTTPS_URL_REGEXP } from 'src/constants'
import { ProjectContext } from 'src/pages/projectDetail'

interface IDepFormProps {
  isAdd: boolean
  visible: boolean
  current: IAppDep | undefined
  onOk: (dep: IAppDep) => any
  onCancel: () => any
}

const DepForm = (props: IDepFormProps) => {
  const { isAdd, visible, current, onOk, onCancel } = props
  const [form] = Form.useForm<IAppDep>()
  const { project } = useContext(ProjectContext)
  const deps = project?.deps || []

  useEffect(() => {
    form.resetFields()
    if (visible) {
      form.setFieldsValue(current)
      if (!current?.urls) {
        form.setFieldValue('urls', [''])
      }
    }
  }, [form, visible, current])

  const handleSave = async () => {
    try {
      const dep = await form.validateFields()
      onOk(dep)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Drawer
      title={isAdd ? '添加工具库' : '编辑工具库'}
      width="600px"
      open={visible}
      onClose={() => onCancel()}
      footer={
        <Space>
          <Button onClick={handleSave} type="primary">
            确定
          </Button>
          <Button onClick={() => onCancel()}>取消</Button>
        </Space>
      }
      keyboard={false}
      maskClosable={false}
    >
      <Form form={form} layout="horizontal" labelCol={{ span: 8 }} labelAlign="right">
        <Form.Item
          label="工具库包名"
          name="package"
          rules={[
            { required: true, message: '必填' },
            {
              validator(rule, value, callback) {
                if (deps.some((dep) => dep !== current && dep.package === value)) {
                  callback('该工具库已添加！')
                } else {
                  callback()
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="版本号"
          name="version"
          rules={[
            {
              validator(rule, value, callback) {
                if (value) {
                  try {
                    new SemVer(value)
                    callback()
                  } catch (err) {
                    callback('版本格式应该为 x.x.x，例如 1.0.0')
                  }
                } else {
                  callback()
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="UMD格式包资源地址"
          tooltip="请填写该NPM包的UMD格式的资源在unpkg.com的地址"
          required
        >
          <Form.List name="urls">
            {(fields, operation, { errors }) => (
              <>
                {fields.map((field) => (
                  <Form.Item
                    {...field}
                    noStyle
                    rules={[
                      { required: true, message: '必填' },
                      {
                        validator(rule, value, callback) {
                          if (value) {
                            if (HTTPS_URL_REGEXP.test(value)) {
                              callback()
                            } else {
                              callback('资源地址格式不正确！')
                            }
                          } else {
                            callback()
                          }
                        },
                      },
                      {
                        validator(rule, value: string, callback) {
                          if (value) {
                            const packageName = form.getFieldValue('package')
                            const version = form.getFieldValue('version')
                            const packageId = version ? `${packageName}@${version}` : packageName
                            if (value.includes(packageId)) {
                              callback()
                            } else {
                              callback('包名，版本号与资源地址不一致！')
                            }
                          } else {
                            callback()
                          }
                        },
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ))}
                <Form.ErrorList errors={errors} />
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item label="UDM格式包导出对象名" name="library" tooltip="例如：lodash的导出对象为 _">
          <Input />
        </Form.Item>
        <Form.Item label="描述信息" name="description">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item label="文档地址" name="docUrl">
          <Input />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default DepForm
