import { FC } from 'react'
import { ReactComponent as OrderDescIcon } from 'src/assets/order_desc.svg'
import { ReactComponent as OrderAscIcon } from 'src/assets/order_asc.svg'
import { Tooltip } from 'antd'

import styles from './style.scss'

export enum OrderType {
  DESC = 'desc',
  ASC = 'asc',
}

const DEFAULT_ASC_TOOLTIP = 'ASC'
const DEFAULT_DESC_TOOLTIP = 'DESC'
const DEFAULT_SVG_SIZE = 18
const DEFAULT_SVG_COLOR = 'rgb(102, 102, 102)'

interface IOrderProps {
  current: OrderType
  onChange: (value: OrderType) => void
  ascTooltip?: string
  descTooltip?: string
  size?: number
  color?: string
}

const Order: FC<IOrderProps> = ({
  current = OrderType.DESC,
  onChange,
  ascTooltip = DEFAULT_ASC_TOOLTIP,
  descTooltip = DEFAULT_DESC_TOOLTIP,
  size = DEFAULT_SVG_SIZE,
  color = DEFAULT_SVG_COLOR,
  ...others
}) => {
  const isDescOrder = current === OrderType.DESC
  return (
    <Tooltip title={isDescOrder ? descTooltip : ascTooltip}>
      <div
        className={styles.iconWrapper}
        onClick={() => onChange(isDescOrder ? OrderType.ASC : OrderType.DESC)}
        {...others}
      >
        {isDescOrder ? (
          <OrderDescIcon height={size} width={size} fill={color} />
        ) : (
          <OrderAscIcon height={size} width={size} fill={color} />
        )}
      </div>
    </Tooltip>
  )
}

export default Order
