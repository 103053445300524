import { message } from 'antd'
import { FC, useEffect, useState } from 'react'
import { IGetDataCenterDataResponse, getDataCenterData } from 'src/api'
import { DATA_CENTER_PROJECT_COUNT_DAYS, DATA_CENTER_RECENTLY_PUBLISH_COUNT } from 'src/constants'
import Overview from './components/Overview'
import RecentlyPublish from './components/RecentlyPublish'
import Statistic from './components/Statistic'
import styles from './style.scss'

const DataCenter: FC = () => {
  const [datasource, setDatasource] = useState<IGetDataCenterDataResponse['data'] | undefined>(undefined)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDataCenterData({
          publishCountDays: DATA_CENTER_PROJECT_COUNT_DAYS,
          recentlyPublishCount: DATA_CENTER_RECENTLY_PUBLISH_COUNT,
        })
        if (res.code === 0 && res.data) {
          setDatasource(res.data)
        } else {
          message.error(res.message)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={styles.root}>
      <Overview datasource={datasource?.overView} projectDays={DATA_CENTER_PROJECT_COUNT_DAYS} />
      {/* <div className={styles.chartsArea}>
        <div className={styles.chartsAreaLeft}>
          <DailyPV className={styles.PVChartArea} datasource={datasource?.dailyPV} />
        </div>
        <div className={styles.chartsAreaRight}></div>
      </div> */}
      <div className={styles.infoListArea}>
        <RecentlyPublish datasource={datasource?.recentlyPublish} />
        <Statistic datasource={datasource?.superCount} />
      </div>
    </div>
  )
}

export default DataCenter
