/* eslint-disable multiline-comment-style */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Modal, ModalProps, Button, } from 'antd'
import { IProject } from 'src/api'
import Collaborators from './Collaborators'
import AuthorizeZone from './AuthorizeZone'

import styles from './style.scss'
import { AnyFunc } from 'src/types/custom'


export enum CreateTypeEnum {
  blank = 'blank',
  template = 'template',
}

export interface IAuthorizeProps {
  visible: boolean
  onOk: AnyFunc
  onCancel: AnyFunc
  createType?: CreateTypeEnum
  data?: IProject
}

const Authorize: FC<IAuthorizeProps> = (props) => {

  const { visible, onOk, onCancel, data } = props

  const handleOk: ModalProps['onOk'] = useCallback(async () => {

  }, [onOk])

  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onCancel()
  }, [onCancel])

  const ModalProps = useMemo(
    () => ({
      className: styles.modal,
      visible,
      onOk: handleOk,
      onCancel: handleCancel,
      footer: null
    }),
    [handleCancel, handleOk, visible],
  )



  return (
    <Modal {...ModalProps} title='授权' destroyOnClose width={800}>
      <div className={styles.modalContent}>
        <div className={styles.collaboratorTitle}>当前协作者</div>
        <Collaborators projectId={data?.id} />
        <div className={styles.newCollaboratorTitle}>新增授权</div>
        <AuthorizeZone projectId={data?.id} />
      </div>
    </Modal>
  )
}

export default Authorize
