import { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTES } from 'src/constants/routes'
import Template from 'src/pages/componentTemplate'
import Course from 'src/pages/course'
import DataCenter from 'src/pages/dataCenter'
import Project from 'src/pages/project'
import ProjectDetail from 'src/pages/projectDetail'
import Material from 'src/pages/systemMaterial'

const Content = ({ isSuper }) => (
  <Suspense>
    <Switch>
      <Route key={ROUTES.APP} path={ROUTES.APP} component={Project} />
      <Route key={ROUTES.APP_DETAIL} path={ROUTES.APP_DETAIL} component={ProjectDetail} />
      <Route key={ROUTES.TEMPLATE} path={ROUTES.TEMPLATE} component={Template} />
      <Route key={ROUTES.COURSE} path={ROUTES.COURSE} component={Course} />
      <Route
        key={ROUTES.WORKBENCH}
        path={ROUTES.WORKBENCH}
        component={lazy(() => import('src/pages/workbench'))}
      />
      <Route
        key={ROUTES.PREVIEW}
        path={ROUTES.PREVIEW}
        component={lazy(() => import('src/pages/preview'))}
      />

      {isSuper ? (
        <Route key={ROUTES.DATA_CENTER} path={ROUTES.DATA_CENTER} component={DataCenter} />
      ) : undefined}
      {isSuper ? (
        <Route key={ROUTES.MATERIAL} path={ROUTES.MATERIAL} component={Material} />
      ) : undefined}

      <Route path="*">
        <Redirect to={ROUTES.APP} />
      </Route>
    </Switch>
  </Suspense>
)

export default Content
