import fetchHandler from "@redhare/datasource-fetch-handler"
import { message } from "antd"
import { IAppDataSource, IAppDataSourceInterface } from "src/api"

type AnyFunc = (...args: any) => any

const compileFunction = (fn: string | undefined) => {
  // eslint-disable-next-line no-new-func
  const evaluator = new Function(`return (${fn})`)
  return evaluator() as AnyFunc
}

const getOptions = (uri: string, paramStr: string, method: string) => {
  let params = {}
  try {
    // eslint-disable-next-line no-new-func
    const evaluator = new Function(`return (${paramStr})`)
    params = evaluator() as any
  }
  catch (ex) {
    console.error('failed to transform params', ex)
    message.error('参数存在错误！')
  }

  return {
    uri,
    params,
    method,
    headers: {},
    isCors: true,
  }
}

export const getInterfaceHandler = (dataSource: IAppDataSource, dsInterface: IAppDataSourceInterface) => {
  const willFetch = `async function willFetch(options) {
  const willFetch = (${dsInterface.willFetchEnabled ? dsInterface.willFetch : 'undefined'});
  const sharedWillFetch = (${dataSource.willFetchEnabled ? dataSource.willFetch : 'undefined'});
  if (willFetch) {
    options = await willFetch(options)
  }
  if (sharedWillFetch) {
    options = await sharedWillFetch(options)
  }
  return options
}`
  const dataHandler = `async function dataHandler(res) {
  const dataHandler = (${dataSource.dataHandlerEnabled ? dataSource.dataHandler : 'undefined'});
  const sharedDataHandler = (${dsInterface.errorHandlerEnabled ? dsInterface.errorHandler : 'undefined'});
  if (dataHandler) {
    res = await dataHandler(res)
  }
  if (sharedDataHandler) {
    res = await sharedDataHandler(res)
  }
  return res
}`

  const errorHandler = `async function errorHandler(error) {
  const sharedErrorHandler = (${dataSource.errorHandlerEnabled ? dataSource.errorHandler : 'undefined'});
  const errorHandler = (${dsInterface.errorHandlerEnabled ? dsInterface.errorHandlerEnabled : 'undefined'});
  if (sharedErrorHandler) {
    await sharedErrorHandler(error)
  }
  if (errorHandler) {
    await errorHandler(error)
  }
}`


  return { willFetch, dataHandler, errorHandler }
}

export const testInterface = async (
  currentDataSource: IAppDataSource,
  currentInterface: IAppDataSourceInterface,
  params: any
) => {
  const { willFetch, dataHandler, errorHandler } = getInterfaceHandler(currentDataSource, currentInterface)

  let willFetchFn: AnyFunc
  let dataHandlerFn: AnyFunc
  let errorHandlerFn: AnyFunc

  try {
    if (willFetch) {
      willFetchFn = compileFunction(willFetch)
    }
  } catch (err) {
    message.error('请求拦截器存在语法错误！')
  }

  try {
    if (dataHandler) {
      dataHandlerFn = compileFunction(dataHandler)
    }
  } catch (err) {
    message.error('结果拦截器存在语法错误！')
  }

  try {
    if (errorHandler) {
      errorHandlerFn = compileFunction(errorHandler)
    }
  } catch (err) {
    message.error('异常拦截器存在语法错误！')
  }

  let options = getOptions(currentInterface.uri, params, currentInterface.method)
  if (willFetch) {
    options = await willFetchFn(options)
  }
  if (!options) {
    message.error('请求拦截器未返回请求配置，请检查！')
  }
  return await fetchHandler(options).then(dataHandlerFn, errorHandlerFn)
}

export const handlerValidator = (value: string) => {
  try {
    const fn = compileFunction(value)
    return typeof fn === 'function'
  } catch (err) {
    return false
  }
}