import { ReactNode, useState, useCallback, useMemo, useEffect } from 'react'
import { List, ListProps } from 'antd'
import InfiniteScroll from 'react-infinite-scroller'
import classNames from 'classnames'
import { useAsyncFn } from 'react-use'
import useQuery from 'src/hooks/useQuery'
import { IPagingParams, IPagingRes } from 'src/types/custom'
import Loading from '../Loading'

import styles from './style.scss'

export interface IFetcherResult<T> {
  total: number
  // page num
  current: number
  record: number
  data: T[]
}

export interface IIFetcherParams {
  start: number
  limit: number
}

export interface IInfiniteScrollListProps<TListItem> extends ListProps<TListItem> {
  className?: string
  limit?: number
  useWindow?: boolean
  getScrollParent?: () => HTMLElement
  fetcher: (params: IPagingParams) => Promise<IPagingRes<TListItem>>
  renderItem: (record: TListItem) => ReactNode
  errorHandler?: (error: any) => void
}

function InfiniteScrollList<TListItem>({
  className,
  limit = 20,
  fetcher,
  renderItem,
  errorHandler,
  useWindow = false,
  getScrollParent,
  ...props
}: IInfiniteScrollListProps<TListItem>) {
  const query = useQuery()
  const [listData, setListData] = useState<TListItem[]>([])
  const [status, fetchData] = useAsyncFn(fetcher, [fetcher])
  const { loading, value, error } = status
  const { records, current = 1, total = 0 } = value || {}

  useEffect(() => {

    const paramData: any = { current: 1, pageSize: limit }
    if (query.sharetoken) {
      paramData.token = query.sharetoken
    }
    fetchData(paramData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit])

  useEffect(() => {
    if (errorHandler) {
      errorHandler(error)
    }
  }, [error, errorHandler])

  useEffect(() => {
    if (records && records.length > 0) {
      setListData((pre) => [...pre, ...records])
    }
  }, [records])

  const hasMore = useMemo(() => {
    if (total > listData.length && total > current + 1) {
      return true
    }
    return false
  }, [current, listData.length, total])

  const handleInfiniteOnLoad = useCallback(async () => {
    if (!loading) {
      fetchData({ current: current + 1, pageSize: limit })
    }
  }, [loading, current, limit, fetchData])

  const isInitializeLoading = useMemo(
    () => listData.length === 0 && loading,
    [listData.length, loading],
  )
  const showBottomLoading = loading && hasMore && !isInitializeLoading

  return (
    <div className={classNames(styles.root, className)}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        hasMore={!loading && hasMore}
        useWindow={useWindow}
        loadMore={handleInfiniteOnLoad}
        getScrollParent={getScrollParent}
      >
        <Loading spinning={isInitializeLoading} renderChildrenWhenLoading={false} size="large">
          <List dataSource={listData} renderItem={renderItem} {...props}>
            {showBottomLoading && (
              <div className={styles.loading}>
                <Loading size="large" />
              </div>
            )}
          </List>
        </Loading>
      </InfiniteScroll>
      {hasMore && current === 1 ? <div className={styles.button} onClick={handleInfiniteOnLoad}>点击加载更多...</div> : <div />}

    </div>
  )
}

export default InfiniteScrollList
