import { FC, useCallback, useEffect, useState } from 'react'
import {
  Table,
  TablePaginationConfig,
  TableColumnsType,
  Button,
  Tooltip,
  message,
  Modal,
  Popover,
  Image,
} from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import {
  deleteComponentTemplate,
  getPrivateComponentTemplates,
  getPublicComponentTemplates,
  IComponentTemplate,
} from 'src/api'
import styles from './style.scss'
import { TemplateType } from '../..'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NUMBER } from 'src/constants'

interface IComponentTemplateTableProps {
  searchKeyWord?: string
  templateTableType: TemplateType
}

const ComponentTemplateTable: FC<IComponentTemplateTableProps> = ({
  searchKeyWord,
  templateTableType,
}) => {
  const [templates, setTemplates] = useState<IComponentTemplate[]>([])
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(DEFAULT_PAGE_NUMBER)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [isFetchingDataSource, setIsFetchingDataSource] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const onDeleteButtonClick = async (id: string) => {
    Modal.confirm({
      content: '确定要删除吗?',
      onOk: async () => {
        setIsDeleting(true)
        try {
          await deleteComponentTemplate(id)
          await fetchComponentTemplateList()
          message.success('Delete successfully')
        } catch (e) {
          message.error(e?.message)
        } finally {
          setIsDeleting(false)
        }
      },
    })
  }

  const columns: TableColumnsType<IComponentTemplate> = [
    {
      title: '模板名称',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <Tooltip title={text}>
          <span className={styles.overflowText}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '模板描述',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <Tooltip title={text}>
          <span className={styles.overflowText}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: '预览图',
      dataIndex: 'previewImageUrl',
      key: 'previewImageUrl',
      render: (previewImageUrl) =>
        previewImageUrl ? (
          <Popover
            content={<Image width={200} height={150} src={previewImageUrl} alt="previewImage" />}
            trigger="click"
            placement="topLeft"
          >
            <PictureOutlined className={styles.previewIcon} />
          </Popover>
        ) : null,
    },
    {
      title: '分组',
      dataIndex: 'group',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Button
          className={styles.actionButton}
          onClick={() => onDeleteButtonClick(record.id)}
          type="link"
          disabled={isDeleting}
        >
          删除
        </Button>
      ),
    },
  ]

  const fetchComponentTemplateList = useCallback(async () => {
    setIsFetchingDataSource(true)

    const { records, total } =
      templateTableType === TemplateType.PUBLIC
        ? await getPublicComponentTemplates(current, searchKeyWord, pageSize)
        : await getPrivateComponentTemplates(current, searchKeyWord, true, pageSize)

    setTemplates(records ?? [])
    setTotal(total ?? 0)

    setIsFetchingDataSource(false)
  }, [current, pageSize, searchKeyWord, templateTableType])

  useEffect(() => {
    try {
      fetchComponentTemplateList()
    } catch (e) {
      console.log(e)
    }
  }, [fetchComponentTemplateList])

  const pagination: TablePaginationConfig = {
    defaultCurrent: DEFAULT_PAGE_NUMBER,
    defaultPageSize: DEFAULT_PAGE_SIZE,
    current,
    pageSize,
    total,
    showSizeChanger: true,
    onChange: (page: number, pageSize: number) => {
      setCurrent(page)
      setPageSize(pageSize)
    },
    onShowSizeChange: (current: number, size: number) => {
      setCurrent(current)
      setPageSize(size)
    },
  }

  return (
    <div className={styles.root}>
      <Table
        columns={columns}
        dataSource={templates}
        pagination={pagination}
        loading={isFetchingDataSource}
        rowKey="id"
      />
    </div>
  )
}

export default ComponentTemplateTable
