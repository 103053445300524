import { FC, useCallback, useState } from 'react'
import { InputNumber, Select, Button, Typography, message as antdMessage } from 'antd';
import { PROJECT_AUTHORITY_TYPE, PROJECT_AUTHORITY_DEFAULT_TYPE_INDEX } from 'src/constants'
import { getTokenWhenProjectAuthorize } from 'src/api'
import styles from './authorizeZone.scss'

const { Paragraph } = Typography;

interface IAuthorizeZoneProps {
    projectId: string
}

const AuthorizeZone: FC<IAuthorizeZoneProps> = (props) => {

    const { projectId } = props

    const [shareUrl, setShareUrl] = useState<string>('');
    const [shouldLoading, setShouldLoading] = useState<boolean>(false);
    const [isShareVisiable, setShareVisiable] = useState<boolean>(false);
    const [authorizeCount, setAuthorizeCount] = useState<string | number | null>(1);
    const [role, setRole] = useState(PROJECT_AUTHORITY_TYPE[PROJECT_AUTHORITY_DEFAULT_TYPE_INDEX].value);

    const handleOnClick = useCallback(async () => {
        if (!Number(authorizeCount)) {
            antdMessage.error('未选择授权数量，至少授权1名用户')
            return
        }
        setShouldLoading(true)
        try {
            const authorizeRes: any = await getTokenWhenProjectAuthorize({
                count: Number(authorizeCount),
                roleName: role,
                projectId
            });

            if (authorizeRes?.code === 0) {
                let currentUrl = window.location.href
                currentUrl = currentUrl.split('?')[0]
                setShareUrl(`${currentUrl}?sharetoken=${authorizeRes?.token}`)
                setShareVisiable(true)
            }
        } catch (e) {
            console.error(e)
        }
        setShouldLoading(false)
    }, [projectId, role, authorizeCount])

    const handleOnRoleChange = useCallback(async (value: any) => {
        setRole(value)
    }, [])

    return <div className={styles.authorizeOuter}>
        <div className={styles.authorizeLine}>
            最多为<InputNumber min={1} className={styles.authorizeNumber} defaultValue={1} value={authorizeCount} onChange={setAuthorizeCount} size="small" />名人员，授予
            <Select className={styles.authorizeSelect} value={role} options={PROJECT_AUTHORITY_TYPE} onChange={handleOnRoleChange} size="small" />权限：

        </div>
        <Button className={styles.authorizeButton} size="small" loading={shouldLoading} onClick={handleOnClick} >获取新的授权链接</Button>
        {
            isShareVisiable ? <div className={styles.shareLine}>
                <span>分享该链接给被授权者:</span>
                <Paragraph className={styles.shareUrl} copyable={{ tooltips: ['复制', '已复制'] }}>{shareUrl}</Paragraph>
            </div> : null
        }

    </div>
}

export default AuthorizeZone