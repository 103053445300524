import { QueryClient, QueryClientProvider } from 'react-query';
import Layout from './components/Layout';
import { registerServiceWorker } from './service-worker-register';
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'

registerServiceWorker()

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={zhCN}>
        <Layout />
      </ConfigProvider>
    </QueryClientProvider>
  );
}
