import { FC } from 'react'
import { IGetDataCenterDataResponse } from 'src/api'
import styles from './statistic.scss'

interface IStatisticProps {
  datasource: IGetDataCenterDataResponse['data']['superCount']
}

const Statistic: FC<IStatisticProps> = (props) => {
  const { datasource } = props

  const mostTimesDom = () => {
    return datasource?.mostVisitors?.map((item, index) => {
      return (
        <span key={`frequencyViewMen-${index}`}>
          {item.userName}（{item.times}）
        </span>
      )
    })
  }

  const mostPublishDom = () => {
    return datasource?.mostPublish?.map((item, index) => {
      return (
        <span key={`frequencyPublishMen-${index}`}>
          {item.userName}（{item.times}）
        </span>
      )
    })
  }

  return (
    <div className={styles.statisticRoot}>
      <span className={styles.title}>超级统计</span>
      <hr />
      <div className={styles.listArea}>
        <span>30日访问排名：</span>
        <div className={styles.listAreaRight}>{mostTimesDom()}</div>
      </div>
      <hr />
      <div className={styles.listArea}>
        <span>30日发布排名：</span>
        <div className={styles.listAreaRight}>{mostPublishDom()}</div>
      </div>
      <hr />
    </div>
  )
}

export default Statistic
