export const BLOCK_ID_MAP = {
  detail: {
    title: '应用',
    id: 'detail',
  },
  portal: {
    title: '网站',
    id: 'portal',
  },
  apiList: {
    title: '接口管理',
    id: 'apiList',
  },
  pageList: {
    title: '页面管理',
    id: 'pageList',
  },
} as const
