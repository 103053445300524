import styles from './style.scss'
import { CloudDownloadOutlined, SettingOutlined } from '@ant-design/icons'
import { Button, Space } from 'antd'
import { FC, useCallback, useState } from 'react'
import Loading from 'src/components/Loading'
import { GET_DEFAULT_APP_AVATAR } from 'src/constants/index'
import Edit from '../../../project/components/Edit'
import { useProjectContext } from '../../index'
import { PageTypeIcon } from 'src/components/ProjectTypeIcon'
import { downloadAppZip } from 'src/api/apiComponents/downloadZip'
import Deps from '../deps'
import Materials from '../materials'
import { AppInterface } from '../interfaces'

export interface IDetailProps {
  info?: string
}

export enum MoreMenuEnum {
  delete = 'delete',
}

const Detail: FC<IDetailProps> = () => {
  const { project, loading, refresh, id } = useProjectContext()
  const [editVisible, setEditVisible] = useState(false)

  const handleSettingClick = useCallback(() => {
    setEditVisible(true)
  }, [])

  const handleDownload = useCallback(() => {
    if (id) {
      downloadAppZip(id)
    }
  }, [id])

  const handleEditOk = useCallback(() => {
    refresh()
    setEditVisible(false)
  }, [refresh])

  const handleEditCancel = useCallback(() => {
    setEditVisible(false)
  }, [])

  return (
    <>
      <Loading spinning={loading}>
        <div className={styles.detail}>
          <img
            className={styles.avatar}
            src={project?.picture || GET_DEFAULT_APP_AVATAR()}
            alt=""
          />
          <div className={styles.detailContent}>
            <div className={styles.appInfo}>
              <h3 className={styles.detailHeader}>
                {project ? (
                  <>
                    <PageTypeIcon type={project.type} />
                    <span>应用：{project.name}</span>
                  </>
                ) : undefined}
              </h3>
              <div className={styles.otherInfo}>
                {project ? `描述：${project.description}` : '-'}
              </div>
            </div>
            <div className={styles.btnWrap}>
              <Space>
                <Button
                  className={styles.btnSet}
                  icon={<SettingOutlined />}
                  onClick={handleSettingClick}
                >
                  设置
                </Button>
                <Button icon={<CloudDownloadOutlined />} onClick={handleDownload}>
                  下载应用
                </Button>
                <Deps />
                <Materials />
                <AppInterface />
              </Space>
            </div>
          </div>
        </div>
      </Loading>

      <Edit
        visible={editVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        title="应用设置"
        data={project}
      />
    </>
  )
}

export default Detail
