import { ENV_ENUM } from 'src/constants'

// env locations
const ENV_LOCATION_HOST = {
  DEV: 'pc.dev.redhare.cc',
  TEST: 'rh-web.test.server.redhare.cc',
  LIVE: 'pc.redhare.cc',
}

// ENV
const hostname = window.location.hostname
let env: ENV_ENUM = ENV_ENUM.LIVE
if (hostname === ENV_LOCATION_HOST.DEV || hostname.includes('localhost')) {
  env = ENV_ENUM.DEV
} else if (hostname === ENV_LOCATION_HOST.TEST) {
  env = ENV_ENUM.TEST
}

export const ENV = env

// env fetch url
const ENV_FETCH_URL = {
  [ENV_ENUM.DEV]: '',
  [ENV_ENUM.TEST]: '//rh-server.test.server.redhare.cc',
  [ENV_ENUM.LIVE]: '//api.redhare.cc/pcapi',
}

export const BASE_URL = ENV_FETCH_URL[ENV]

