import { REQ_URI } from 'src/constants/index'
import fetch from 'src/helpers/fetch'

interface IGetDataCenterDataPrams {
  publishCountDays?: number
  recentlyPublishCount?: number
}

export interface IGetDataCenterDataResponse {
  code: number
  message: string
  data?: {
    overView: {
      totalUsers: number
      projectsCount: number
      publishCount: number
      avgPV: number
    }
    dailyPV: {
      date: number
      pv: number
    }[]
    recentlyPublish: {
      createdBy: string
      env: string
      ownerId: string
      schemaId: string
      createdAt: number
      changelog: string
    }[]
    superCount: {
      mostVisitors: {
        userName: string
        times: number
      }[]
      mostPublish: {
        userName: string
        times: number
      }[]
    }
  }
}

export const getDataCenterData = async (params: IGetDataCenterDataPrams): Promise<IGetDataCenterDataResponse> =>
  fetch({ method: 'POST', resource: `${REQ_URI.GET_DATA_CENTER_DATA}`, payload: params })
