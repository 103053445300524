import { DownloadOutlined } from '@ant-design/icons'
import { Button, Drawer, Form, Input, Select, Space, Tooltip } from 'antd'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getMaterialVersions, IMaterial } from 'src/api'
import { ProjectContext } from 'src/pages/projectDetail'

interface IMaterialFormProps {
  visible: boolean
  isAdd: boolean
  systemMaterials: IMaterial[]
  current: IMaterial | undefined
  onOk: (dep: IMaterial) => any
  onCancel: () => any
}

const MaterialForm = (props: IMaterialFormProps) => {
  const { isAdd, systemMaterials, visible, current, onOk, onCancel } = props
  const [form] = Form.useForm<IMaterial>()
  const { project } = useContext(ProjectContext)
  const materials = project?.materials || []
  const [versions, setVersions] = useState<string[]>([])
  const [versionLoading, setVersionLoading] = useState(false)

  const handleSave = async () => {
    try {
      const dep = await form.validateFields()
      onOk(dep)
    } catch (err) {
      console.error(err)
    }
  }

  const getVersions = useCallback(async () => {
    const materialName = form.getFieldValue('name')
    if (materialName) {
      setVersionLoading(true)
      const versions = await getMaterialVersions(materialName)
      if (Array.isArray(versions)) {
        setVersions(versions.reverse())
      } else {
        setVersions([versions])
      }
      setVersionLoading(false)
    }
  }, [form])

  useEffect(() => {
    form.resetFields()
    if (visible) {
      form.setFieldsValue(current)
      if (current?.name) {
        getVersions()
      }
    }
  }, [form, visible, current, getVersions])

  return (
    <Drawer
      title={isAdd ? '添加物料库' : '编辑物料库'}
      width="600px"
      open={visible}
      onClose={() => onCancel()}
      footer={
        <Space>
          <Button onClick={handleSave} type="primary">
            确定
          </Button>
          <Button onClick={() => onCancel()}>取消</Button>
        </Space>
      }
      keyboard={false}
      maskClosable={false}
    >
      <Form form={form} layout="horizontal" labelCol={{ span: 4 }} labelAlign="right">
        <Form.Item
          label="物料库包名"
          name="name"
          rules={[
            { required: true, message: '必填' },
            {
              validator(rule, value, callback) {
                if (systemMaterials.some((dep) => dep.name === value)) {
                  callback('该物料库是系统默认物料库，无需添加！')
                } else if (materials.some((dep) => dep !== current && dep.name === value)) {
                  callback('该物料库已添加！')
                } else {
                  callback()
                }
              },
            },
          ]}
        >
          <Input
            disabled={!isAdd}
            onChange={() => setVersions([])}
            suffix={
              <Tooltip title="获取版本">
                <DownloadOutlined onClick={getVersions} />
              </Tooltip>
            }
          />
        </Form.Item>
        <Form.Item
          label="版本号"
          name="version"
          rules={[
            {
              required: true,
              message: '必填',
            },
          ]}
        >
          <Select
            loading={versionLoading}
            options={versions.map((v) => ({ value: v, label: v }))}
          />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default MaterialForm
