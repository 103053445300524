import { CodeOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Popover,
  Space,
  Switch,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
  message,
} from 'antd'
import MonacoEditor from '@alilc/lowcode-plugin-base-monaco-editor'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { HttpMethod, IAppDataSource, IAppDataSourceInterface } from 'src/api'
import { InterfaceDrawer } from './interface-drawer'
import { DataSourceContext } from './context'
import { DEFAULT_INTERCEPTOR } from './const'
import { handlerValidator } from './utils'

interface IInterfaceGroupProps {
  mode: 'edit' | 'view'
  visible: boolean
  onOk: (dataSource: IAppDataSource) => Promise<void>
  onCancel: () => void
  onChangeMode: (mode: 'edit' | 'view') => void
}

const CodeViewer = ({
  title,
  code,
  enabled,
}: {
  title: string
  code: string | undefined
  enabled: boolean
}) => {
  return enabled ? (
    <Popover
      title={title}
      content={
        <div style={{ height: '300px', width: '600px' }}>
          <MonacoEditor
            value={code}
            style={{ border: '1px solid #ccc', height: '100%' }}
            options={{ readOnly: true }}
          />
        </div>
      }
    >
      <Button icon={<CodeOutlined />} size="small" type="text">
        查看
      </Button>
    </Popover>
  ) : (
    <>未启用</>
  )
}

export const InterfaceGroupDrawer = (props: IInterfaceGroupProps) => {
  const { visible, mode, onOk, onCancel, onChangeMode } = props
  const { dataSource, setDataSource, dataSourceList } = useContext(DataSourceContext)
  const [interfaceModalVisible, setInterfaceModalVisible] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [currentInterfaceIndex, setCurrentInterfaceIndex] = useState<number>(-1)
  const [form] = Form.useForm<IAppDataSource>()
  const [willFetchEnabled, setWillFetchEnabled] = useState(false)
  const [dataHandlerEnabled, setDataHandlerEnabled] = useState(false)
  const [errorHandlerEnabled, setErrorHandlerEnabled] = useState(false)

  const title = useMemo(() => {
    if (mode === 'view') {
      return '查看接口集合'
    } else {
      return '编辑接口集合'
    }
  }, [mode])

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(dataSource)
    setWillFetchEnabled(dataSource?.willFetchEnabled)
    setDataHandlerEnabled(dataSource?.dataHandlerEnabled)
    setErrorHandlerEnabled(dataSource?.errorHandlerEnabled)
  }, [dataSource, form])

  const handleOk = async () => {
    const dataSource = await form.validateFields()
    onOk(dataSource)
  }

  const handleEdit = async () => {
    onChangeMode('edit')
  }

  const addInterface = async () => {
    try {
      const dataSource = await form.validateFields()
      setDataSource(dataSource)
      setInterfaceModalVisible(true)
      setIsAdd(true)
      setCurrentInterfaceIndex(-1)
    } catch (err) {
      message.error('接口集合存在非法字段！')
    }
  }

  const editInterface = useCallback(
    async (index: number) => {
      try {
        const dataSource = await form.validateFields()
        setDataSource(dataSource)
        setCurrentInterfaceIndex(index)
        setIsAdd(false)
        setInterfaceModalVisible(true)
      } catch (err) {
        message.error('接口集合存在非法字段！')
      }
    },
    [form, setDataSource],
  )

  const deleteInterface = useCallback(
    (index: number) => {
      Modal.confirm({
        title: '确认删除当前接口吗？',
        onOk: () => {
          dataSource.interfaces.splice(index, 1)
          setDataSource({ ...dataSource, interfaces: dataSource.interfaces.slice() })
        },
      })
    },
    [dataSource, setDataSource],
  )

  const handleSaveInterface = (dsInterface: IAppDataSourceInterface) => {
    if (isAdd) {
      dataSource.interfaces.push(dsInterface)
    } else {
      dataSource.interfaces.splice(currentInterfaceIndex, 1, dsInterface)
    }
    setDataSource({ ...dataSource, interfaces: dataSource.interfaces.slice() })
    setInterfaceModalVisible(false)
  }

  const columns = useMemo(() => {
    const columns: TableColumnType<IAppDataSourceInterface>[] = [
      {
        dataIndex: 'id',
        title: 'ID',
      },
      {
        dataIndex: 'uri',
        title: '接口地址',
      },
      {
        dataIndex: 'method',
        title: '请求方式',
        render: (val: string) => <Tag>{val.toUpperCase()}</Tag>,
      },
      {
        dataIndex: 'willFetch',
        title: '请求拦截器',
        render: (val: string, record) => (
          <CodeViewer title="请求拦截器" code={val} enabled={record.willFetchEnabled} />
        ),
      },
      {
        dataIndex: 'dataHandler',
        title: '结果拦截器',
        render: (val: string, record) => (
          <CodeViewer title="结果拦截器" code={val} enabled={record.dataHandlerEnabled} />
        ),
      },
      {
        dataIndex: 'errorHandler',
        title: '异常拦截器',
        render: (val: string, record) => (
          <CodeViewer title="异常拦截器" code={val} enabled={record.errorHandlerEnabled} />
        ),
      },
    ]
    if (mode === 'edit') {
      columns.push({
        dataIndex: '',
        title: '操作',
        render: (_: any, __, index) => (
          <Space>
            <Tooltip title="编辑">
              <Button
                size="small"
                onClick={() => editInterface(index)}
                icon={<EditOutlined />}
                type="text"
              />
            </Tooltip>
            <Tooltip title="删除">
              <Button
                size="small"
                onClick={() => deleteInterface(index)}
                icon={<DeleteOutlined />}
                type="text"
              />
            </Tooltip>
          </Space>
        ),
      })
    }
    return columns
  }, [mode, deleteInterface, editInterface])

  if (!dataSource) return

  return (
    <Drawer
      visible={visible}
      width="800px"
      title={title}
      onClose={() => onCancel()}
      keyboard={false}
      footer={
        mode === 'view' ? (
          <Space>
            <Button onClick={() => handleEdit()} type="primary">
              编辑
            </Button>
            <Button onClick={() => onCancel()}>关闭</Button>
          </Space>
        ) : (
          <Space>
            <Button onClick={() => handleOk()} type="primary">
              保存
            </Button>
            <Button onClick={() => onCancel()}>关闭</Button>
          </Space>
        )
      }
    >
      <Form form={form} layout="vertical" colon>
        <Form.Item name="id" noStyle />
        <Form.Item name="version" noStyle />
        <Form.Item
          label="名称"
          name="name"
          rules={
            mode === 'view'
              ? undefined
              : [
                  { required: true, message: '必填' },
                  {
                    validator(rule, value: string, callback) {
                      const hasSameName = dataSourceList.some(
                        (item) => item.id !== dataSource.id && item.name === value,
                      )
                      if (hasSameName) {
                        callback(`接口集合${value}已存在！`)
                      } else {
                        callback()
                      }
                    },
                  },
                ]
          }
        >
          {mode === 'view' ? <span>{dataSource?.name}</span> : <Input />}
        </Form.Item>
        <Form.Item label="描述" name="description" rules={[{ max: 255, message: '描述信息过长' }]}>
          {mode === 'view' ? <span>{dataSource?.description}</span> : <Input.TextArea />}
        </Form.Item>

        <Form.Item label="共享拦截器">
          <Alert type="info" message="当前接口集合的接口可以共享以下拦截器" />
          <Form.Item name="willFetchEnabled" noStyle />
          <Form.Item
            name="willFetch"
            label={
              <Space>
                <span>请求拦截器</span>
                <Switch
                  size="small"
                  checked={willFetchEnabled}
                  onChange={() => {
                    setWillFetchEnabled(!willFetchEnabled)
                    form.setFieldValue('willFetchEnabled', !willFetchEnabled)
                  }}
                  disabled={mode === 'view'}
                />
              </Space>
            }
            initialValue={DEFAULT_INTERCEPTOR.WILL_FETCH}
            rules={[
              { required: willFetchEnabled, message: '必填！' },
              {
                validator(rule, value, callback) {
                  if (value) {
                    if (handlerValidator(value)) {
                      callback()
                    } else {
                      callback('拦截器不合法！')
                    }
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            {willFetchEnabled ? (
              mode === 'view' ? (
                <MonacoEditor
                  key="view"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  options={{ readOnly: true }}
                />
              ) : (
                <MonacoEditor
                  key="edit"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                />
              )
            ) : (
              ''
            )}
          </Form.Item>

          <Form.Item name="dataHandlerEnabled" noStyle />
          <Form.Item
            name="dataHandler"
            label={
              <Space>
                <span>结果拦截器</span>
                <Switch
                  size="small"
                  checked={dataHandlerEnabled}
                  onChange={() => {
                    setDataHandlerEnabled(!dataHandlerEnabled)
                    form.setFieldValue('dataHandlerEnabled', !dataHandlerEnabled)
                  }}
                  disabled={mode === 'view'}
                />
              </Space>
            }
            rules={[
              { required: dataHandlerEnabled, message: '必填！' },
              {
                validator(rule, value, callback) {
                  if (value) {
                    if (handlerValidator(value)) {
                      callback()
                    } else {
                      callback('拦截器不合法！')
                    }
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            {dataHandlerEnabled ? (
              mode === 'view' ? (
                <MonacoEditor
                  key="view"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  defaultValue={DEFAULT_INTERCEPTOR.DATA_HANDLER}
                  options={{ readOnly: true }}
                />
              ) : (
                <MonacoEditor
                  key="edit"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                />
              )
            ) : (
              ''
            )}
          </Form.Item>

          <Form.Item name="errorHandlerEnabled" noStyle />
          <Form.Item
            name="errorHandler"
            label={
              <Space>
                <span>异常拦截器</span>
                <Switch
                  size="small"
                  checked={errorHandlerEnabled}
                  onChange={() => {
                    setErrorHandlerEnabled(!errorHandlerEnabled)
                    form.setFieldValue('errorHandlerEnabled', !errorHandlerEnabled)
                  }}
                  disabled={mode === 'view'}
                />
              </Space>
            }
            initialValue={DEFAULT_INTERCEPTOR.ERROR_HANDLER}
            rules={[
              { required: errorHandlerEnabled, message: '必填！' },
              {
                validator(rule, value, callback) {
                  if (value) {
                    if (handlerValidator(value)) {
                      callback()
                    } else {
                      callback('拦截器不合法！')
                    }
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            {errorHandlerEnabled ? (
              mode === 'view' ? (
                <MonacoEditor
                  key="view"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                  options={{ readOnly: true }}
                />
              ) : (
                <MonacoEditor
                  key="edit"
                  style={{ border: '1px solid #ccc', overflow: 'hidden' }}
                  language="javascript"
                />
              )
            ) : (
              ''
            )}
          </Form.Item>
        </Form.Item>

        <Form.Item label="接口列表" name="interfaces">
          <Table
            columns={columns}
            dataSource={dataSource.interfaces || []}
            footer={
              mode === 'view'
                ? undefined
                : () => (
                    <Button
                      style={{ display: 'block', width: '100%' }}
                      icon={<PlusOutlined />}
                      onClick={() => addInterface()}
                    >
                      添加接口
                    </Button>
                  )
            }
          />
        </Form.Item>
      </Form>

      <InterfaceDrawer
        visible={interfaceModalVisible}
        mode={mode}
        isAdd={isAdd}
        currentInterfaceIndex={currentInterfaceIndex}
        currentDataSource={dataSource}
        onCancel={() => setInterfaceModalVisible(false)}
        onOk={(dsInterface) => handleSaveInterface(dsInterface)}
      />
    </Drawer>
  )
}
