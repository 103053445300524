import { PagePublishStatusEnum } from 'src/api'
import fetch from 'src/helpers/fetch'

export interface IPortalForm {
	id?: string
	projectId: string
	name: string
	faviconIco: string
}

export interface IPortal extends IPortalForm {
	id: string
	uuid: string
	publishStatus: PagePublishStatusEnum
	createdAt: number
	createdBy: string
	updatedAt: number
	updatedBy: string
}

export const getPortalByProjectId = async (projectId) =>
	fetch<IPortal>({
		method: 'GET',
		resource: 'portal/in-project',
		params: { projectId },
	})

export const getPortalById = async (portalId, projectId?: string) =>
	fetch<IPortal>({
		method: 'GET',
		resource: `portal/${portalId}}`,
	})

export const enablePortal = async (portal: IPortalForm) =>
	fetch<IPortal>({
		method: 'POST',
		resource: 'portal',
		payload: portal,
	})

export const modifyPortalSetting = async (portal: IPortalForm) =>
	fetch<IPortal>({
		method: 'PUT',
		resource: `portal/${portal.id}`,
		payload: portal,
	})
