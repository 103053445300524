import { DeleteOutlined, EditOutlined, FunctionOutlined, PlusOutlined } from '@ant-design/icons'
import { Alert, Button, Drawer, Modal, Space, Table, TableColumnsType, Tag, Tooltip } from 'antd'
import { useContext, useMemo, useState } from 'react'
import { IAppDep, patchProject } from 'src/api'
import { ProjectContext } from 'src/pages/projectDetail'
import DepForm from './dep-form'
import { DEFAULT_DEPS, LODASH_DEP, MOMENT_DEP } from './const'

const openNpm = (packageName: string) => {
  window.open(`https://www.npmjs.com/${packageName}`, '_blank')
}

const Deps = () => {
  const { project, refresh } = useContext(ProjectContext)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [depFormOpen, setDepFormOpen] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [current, setCurrent] = useState<IAppDep>()
  const deps = useMemo(() => project?.deps || [], [project])

  const handleAdd = () => {
    setDepFormOpen(true)
    setIsAdd(true)
    setCurrent(undefined)
  }

  const handleSaveDep = async (dep: IAppDep) => {
    if (isAdd) {
      deps.push(dep)
    } else {
      const index = deps.indexOf(current)
      if (index > -1) {
        deps.splice(index, 1, dep)
      }
    }
    project.deps = deps
    setLoading(true)
    await patchProject(project)
    await refresh()
    setLoading(false)
    setDepFormOpen(false)
  }

  const handleEdit = (dep: IAppDep) => {
    setDepFormOpen(true)
    setIsAdd(false)
    setCurrent(dep)
  }

  const handleDelete = (dep: IAppDep) => {
    Modal.confirm({
      content: '确认要删除吗？',
      onOk: async () => {
        const index = deps.indexOf(dep)
        if (index > -1) {
          deps.splice(index, 1)
          project.deps = deps
          setLoading(true)
          await patchProject(project)
          await refresh()
          setLoading(false)
        }
      },
    })
  }

  const columns: TableColumnsType<IAppDep> = [
    {
      title: '包名',
      dataIndex: 'package',
      render: (packageName: string, dep) =>
        dep.description ? (
          <Tooltip title={dep.description}>
            <Tag onClick={() => openNpm(packageName)}>{packageName}</Tag>
          </Tooltip>
        ) : (
          <Tag onClick={() => openNpm(packageName)}>{packageName}</Tag>
        ),
    },
    {
      title: '版本号',
      dataIndex: 'version',
    },
    {
      title: 'UDM格式包导出对象名',
      dataIndex: 'library',
      render: (libraryName: string) => (libraryName ? <Tag>{libraryName}</Tag> : '未提供'),
    },
    {
      title: '操作',
      render: (_, dep, index) =>
        DEFAULT_DEPS.some((item) => item.package === dep.package) ? (
          <Space>
            <Tooltip title="默认工具库不可编辑">
              <Button disabled icon={<EditOutlined />} />
            </Tooltip>
            <Tooltip title="默认工具库不可删除">
              <Button disabled icon={<DeleteOutlined />} />
            </Tooltip>
          </Space>
        ) : (
          <Space>
            <Button icon={<EditOutlined />} onClick={() => handleEdit(dep)} />
            <Button icon={<DeleteOutlined />} onClick={() => handleDelete(dep)} />
          </Space>
        ),
    },
  ]

  return (
    <>
      <Drawer
        width="800px"
        open={open}
        title="工具库"
        onClose={() => setOpen(false)}
        footer={
          <Space>
            <Button onClick={() => setOpen(false)} loading={loading}>
              关闭
            </Button>
          </Space>
        }
      >
        <Alert
          type="info"
          message="工具库是由工具函数组成的npm包，如lodash，moment等。"
          description={
            <ul style={{ listStyle: 'disc', padding: '0 30px', marginTop: '20px' }}>
              <li>
                赤兔可以加载
                <Button
                  type="link"
                  size="small"
                  href="https://github.com/umdjs/umd"
                  target="_blank"
                >
                  UMD格式
                </Button>
                的工具库资源
              </li>
              <li>
                赤兔建议您使用发布到
                <Button type="link" size="small" href="https://www.npmjs.com" target="_blank">
                  npm
                </Button>
                的工具库
              </li>
              <li>
                赤兔已经默认提供了
                <Button type="link" size="small" href={MOMENT_DEP.docUrl} target="_blank">
                  moment
                </Button>
                和
                <Button type="link" size="small" href={LODASH_DEP.docUrl} target="_blank">
                  lodash
                </Button>
              </li>
            </ul>
          }
        ></Alert>
        <Table
          columns={columns}
          dataSource={DEFAULT_DEPS.concat(deps)}
          footer={() => (
            <Button
              style={{ display: 'block', width: '100%' }}
              icon={<PlusOutlined />}
              onClick={() => handleAdd()}
            >
              添加工具库
            </Button>
          )}
        />
        <DepForm
          isAdd={isAdd}
          visible={depFormOpen}
          current={current}
          onCancel={() => setDepFormOpen(false)}
          onOk={(dep) => handleSaveDep(dep)}
        />
      </Drawer>
      <Button onClick={() => setOpen(true)} icon={<FunctionOutlined />}>
        工具库
      </Button>
    </>
  )
}

export default Deps
