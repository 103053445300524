import { Button, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { FC, useCallback, useState } from 'react'

import Edit, { EditTypeEnum } from '../Edit'
import styles from './style.scss'
import { IFilterData } from '../..'
export interface IAppCreationProps {
  onRefresh: (filterData: IFilterData) => void
}


const AppCreation: FC<IAppCreationProps> = ({ onRefresh }) => {
  const [visible, setVisible] = useState(false)

  const handleCreateClick = useCallback(() => {
    setVisible(true)
  }, [])

  const handleEditOk = useCallback(() => {
    setVisible(false)
    onRefresh({ keyword: '' })
  }, [onRefresh])

  const handleEditCancel = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      <Button className={styles.createButtonStyle} type="primary" onClick={handleCreateClick} icon={<PlusOutlined />}>
        创建应用
      </Button>
      <Edit
        visible={visible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        title="创建应用"
        type={EditTypeEnum.create}
      />
    </>
  )
}

export default AppCreation
