/* eslint-disable multiline-comment-style */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import ImgCrop from 'antd-img-crop';
import { Modal, ModalProps, Form, Input, Space, Button, Upload, Radio } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { AppstoreAddOutlined, EditOutlined, LaptopOutlined, LoadingOutlined, MobileOutlined } from '@ant-design/icons'
import { IProject, IProjectInfo, PageType, addProject, patchProject } from 'src/api'
import { UPLOAD_URI, GET_DEFAULT_APP_AVATAR } from 'src/constants'
import { useMutation } from 'react-query'
import { getUserName } from 'src/helpers/getUserName'
import { PageTypeIcon } from 'src/components/ProjectTypeIcon';
import { AnyFunc } from 'src/types/custom'

import styles from './style.scss'


export enum EditTypeEnum {
  create = 'create',
  modify = 'modify',
}

export enum CreateTypeEnum {
  blank = 'blank',
  template = 'template',
}

export interface IEditProps {
  visible: boolean
  onOk: AnyFunc
  onCancel: AnyFunc
  title: string
  type?: EditTypeEnum
  createType?: CreateTypeEnum
  data?: IProject
}

const Edit: FC<IEditProps> = (props) => {
  const { visible, onOk, onCancel, title, type = EditTypeEnum.modify, data } = props
  const [form] = Form.useForm<IProjectInfo>()

  const [pictureUrl, setPictureUrl] = useState(data?.picture);
  const [loading, setLoading] = useState(false);

  const setInitialFormVal = useCallback(() => {
    const formData = type === EditTypeEnum.modify ? { ...data } :
      { name: '', description: '', type: PageType.PC }
    form.setFieldsValue(formData)
  }, [data, form, type])

  useEffect(() => {
    setInitialFormVal()
  }, [data, form, setInitialFormVal, type])

  const { mutateAsync: mutateAddProject, isLoading: isAdding } = useMutation((data: IProjectInfo) => {
    data.createdBy = getUserName()
    return addProject(data)
  }
    ,
  )
  const { mutateAsync: mutatePatchProject, isLoading: isPatching } = useMutation((data: IProject) => {
    data.updatedBy = getUserName()
    return patchProject(data)
  }
    ,
  )

  const handleOk: ModalProps['onOk'] = useCallback(async () => {
    try {
      const formData = await form.validateFields()
      formData.picture = pictureUrl || GET_DEFAULT_APP_AVATAR()
      switch (type) {
        case EditTypeEnum.create:
          await mutateAddProject({ ...formData })
          break
        case EditTypeEnum.modify:
          await mutatePatchProject({ ...data, ...formData })
          break;
        default:
          break
      }
      onOk()
      if (type === EditTypeEnum.create) {
        form.resetFields()
      }
    } catch (ex) {
      console.log((ex as Error).message)
    }
  }, [form, type, onOk, mutateAddProject, mutatePatchProject, data, pictureUrl])

  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onCancel()
    setInitialFormVal()
  }, [onCancel, setInitialFormVal])

  const ModalProps = useMemo(
    () => ({
      className: styles.modal,
      title: (
        <Space size="small">
          <AppstoreAddOutlined />
          {title}
        </Space>
      ),
      visible,
      onOk: handleOk,
      onCancel: handleCancel,
      footer: [
        <Button key="cancel" onClick={handleCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" loading={isAdding || isPatching} onClick={handleOk}>
          保存
        </Button>,
      ],
    }),
    [handleCancel, handleOk, isAdding, isPatching, title, visible],
  )

  const onChangePicture: UploadProps['onChange'] = ({ file }) => {
    if (file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (file.status === 'done') {
      setLoading(false);
      setPictureUrl(file.response?.data?.filePath);
    }
  };

  const onPreviewPicture = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const PictureNode = <div className={styles.uploadOuter}>
    <ImgCrop rotationSlider>
      <Upload
        action={UPLOAD_URI}
        listType="picture-card"
        method='POST'
        onChange={onChangePicture}
        onPreview={onPreviewPicture}
        showUploadList={false}
        onRemove={() => false}
        withCredentials={true}
        className={styles.upload}
        data={{ "bucketName": "external" }}
      >
        <div className={styles.pictureOuter}>
          {
            loading ? <LoadingOutlined /> : <>
              <img src={pictureUrl} className={styles.picture} />
              <EditOutlined className={styles.edit} />
            </>
          }

        </div>
      </Upload>
    </ImgCrop>
  </div>

  return (
    <Modal {...ModalProps} destroyOnClose width={1000}>
      <Form form={form} layout="vertical">
        <Form.Item required label="应用图标">
          <div className={styles.logoWrapper}>
            {PictureNode}
          </div>
        </Form.Item>
        <Form.Item label="应用名称" name="name" rules={[{ required: true }]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="应用描述" name="description">
          <Input.TextArea placeholder="请输入" />
        </Form.Item>
        <Form.Item label="应用类型" name="type" rules={[{ required: true }]}>
          {
            type === EditTypeEnum.modify ? <>
              {data ? <PageTypeIcon type={data.type} /> : undefined}
            </> : <Radio.Group optionType="button" buttonStyle="solid">
              <Radio.Button value={PageType.PC}>
                PC应用 <LaptopOutlined />
              </Radio.Button>
              <Radio.Button value={PageType.H5}>
                H5应用 <MobileOutlined />
              </Radio.Button>
            </Radio.Group>
          }
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Edit
