import { FC, useCallback, useState } from 'react'
import { Modal, ModalProps, Space } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import Steps from './components/Steps'

import styles from './style.scss'
import History from './components/History'
import { noop } from 'src/helpers/function'
import { INSTANCE_TYPE } from 'src/constants'
import { AnyFunc } from 'src/types/custom'

export interface IPublishPaneProps {
  instanceId: string
  projectId: string
  instanceType: INSTANCE_TYPE
  visible: boolean
  title?: string
  onPublish?: AnyFunc
  onRevert?: AnyFunc
  onClose?: AnyFunc
  withHistory?: boolean
}

const PublishPane: FC<IPublishPaneProps> = ({
  instanceId,
  projectId,
  instanceType,
  visible,
  title = '发布',
  onPublish = noop,
  onRevert = noop,
  onClose = noop,
  withHistory,
}) => {
  const handleCancel: ModalProps['onCancel'] = useCallback(() => {
    onClose()
  }, [onClose])

  const [refreshKey, setRefreshKey] = useState(0)

  /**
   * refresh page-publish component
   */
  const handleRefresh = useCallback(() => {
    setRefreshKey((refreshKey + 1) % 2)
  }, [refreshKey, setRefreshKey])

  const handleRevert = useCallback(() => {
    handleRefresh()
    onRevert()
  }, [handleRefresh, onRevert])

  const handlePublish = useCallback(() => {
    handleRefresh()
    onPublish()
  }, [handleRefresh, onPublish])

  const modalProps: ModalProps = {
    className: styles.modal,
    keyboard: false,
    title: (
      <Space size="small">
        <CloudUploadOutlined />
        {title}
      </Space>
    ),
    footer: null,
    visible,
    onCancel: handleCancel,
  }

  return (
    <Modal {...modalProps} destroyOnClose>
      <div className={styles.root}>
        <div>
          <Steps
            key={refreshKey}
            onPublish={handlePublish}
            instanceId={instanceId}
            projectId={projectId}
            instanceType={instanceType}
          />
        </div>
        {withHistory && (
          <div>
            <History
              key={refreshKey}
              onRevert={handleRevert}
              onClose={onClose}
              instanceId={instanceId}
              projectId={projectId}
              instanceType={instanceType}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default PublishPane
