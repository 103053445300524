import { FC } from 'react'
import { Typography, Space, Tabs } from 'antd'
import { PagePublishEnvEnum } from 'src/api'
import classNames from 'classnames'
import { noop } from 'src/helpers/function'
import HistoryTable from '../HistoryTable'
import { INSTANCE_TYPE } from 'src/constants'
import { AnyFunc } from 'src/types/custom'

import styles from './style.scss'

export interface IHistoryProps {
  instanceId: string
  projectId: string
  instanceType: INSTANCE_TYPE
  className?: string
  onRevert?: AnyFunc
  onClose?: AnyFunc
}

const LIST_TYPE: { env: PagePublishEnvEnum; title: string }[] = [
  {
    env: PagePublishEnvEnum.test,
    title: '测试环境',
  },
  {
    env: PagePublishEnvEnum.live,
    title: '正式环境',
  },
]

const History: FC<IHistoryProps> = ({
  instanceId,
  projectId,
  className,
  instanceType,
  onRevert = noop,
  onClose = noop,
}) => (
  <div className={classNames(styles.root, className)}>
    <Space direction="vertical" className={styles.history}>
      <Typography.Title level={5}>历史记录</Typography.Title>
      <div className={styles.historyContent}>
        <Tabs defaultActiveKey={PagePublishEnvEnum.test}>
          {LIST_TYPE.map(({ env, title }) => (
            <Tabs.TabPane tab={title} key={env} className={styles[env]}>
              <HistoryTable
                className={styles.table}
                onRevert={onRevert}
                env={env}
                instanceId={instanceId}
                projectId={projectId}
                instanceType={instanceType}
                onClose={onClose}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </Space>
  </div>
)

export default History
