export function noop<T = any>(...args: T[]): T[] {
  return args
}

export function getIndexes(length: number) {
  const indexes = []
  for (let i = 0; i < length; i++) {
    indexes.push(i)
  }
  return indexes
}

export function extractComment(functionTemplate: string) {
  if (!functionTemplate || typeof functionTemplate !== 'string') return ''
  const matchComment = functionTemplate.match(/\/\/[^\n]+\n/g)
  let comment = matchComment?.[0] || ''
  // 去掉斜杠和回车
  comment = comment.replace(/^\/\/\s*|\n$/g, '')
  // 修改措辞以方便非研发人员用户理解
  if (comment.includes('回调')) {
    comment = comment.replace(/(的回调|回调|回调事件)$/, '')
    if (!comment.startsWith('当')) {
      comment = `当${comment}`
    }
  }
  return comment
}
