import { IAppDep } from 'src/api'

export const LODASH_DEP: IAppDep = {
  package: 'lodash',
  version: '4.6.1',
  urls: ['https://cdn.redhare.cc/lodash@4.6.1/lodash.min.js'],
  description: 'Lodash 是一个一致性、模块化、高性能的 JavaScript 实用工具库。',
  docUrl: 'https://www.lodashjs.com/',
  library: '_',
}

export const MOMENT_DEP: IAppDep = {
  package: 'moment',
  version: '2.24.0',
  urls: ['https://cdn.redhare.cc/moment@2.24.0/min/moment.min.js'],
  description: '时间处理工具库',
  docUrl: 'https://momentjs.com/',
  library: 'moment',
}

export const DEFAULT_DEPS: IAppDep[] = [MOMENT_DEP, LODASH_DEP]
