import { FC } from 'react'
import { Spin, SpinProps } from 'antd'

import styles from './style.scss'

export interface ILoadingProps extends SpinProps {
  renderChildrenWhenLoading?: boolean
}

const Loading: FC<ILoadingProps> = ({
  children,
  renderChildrenWhenLoading = true,
  spinning,
  style,
  ...others
}) => {
  if ((!renderChildrenWhenLoading && spinning) || !children) {
    return (
      <div className={styles.spinWrapper} style={style}>
        <Spin spinning={spinning} {...others} />
      </div>
    )
  }
  return (
    <Spin style={style} spinning={spinning} {...others}>
      {children}
    </Spin>
  )
}

export default Loading
