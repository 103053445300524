import {
  CloudUploadOutlined,
  CopyOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleFilled,
  MoreOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Badge, Button, Card, Dropdown, Menu, MenuProps, message, Modal, Space } from 'antd'
import { FC, useCallback, useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { delPage, IPage, PagePublishStatusEnum } from 'src/api'
import PublishPane from 'src/components/Publish'
import { INSTANCE_TYPE } from 'src/constants'
import { GET_DEFAULT_APP_AVATAR } from 'src/constants/index'
import { ROUTES } from 'src/constants/routes'
import { noop } from 'src/helpers/function'
import { AnyFunc } from 'src/types/custom'
import { useProjectContext } from '../..'
import PageCopy from '../PageCopy'
import PageEdit from '../PageEdit'
import styles from './style.scss'

export interface IPageCardProps {
  page: IPage
  className?: string
  onRefresh?: AnyFunc
}

export enum MoreMenuEnum {
  delete = 'delete',
  settings = 'settings',
  duplicate = 'duplicate',
  preview = 'preview',
  publish = 'publish',
}
const PageCard: FC<IPageCardProps> = ({ page, onRefresh = noop }) => {
  const { id: projectId } = useProjectContext()

  const publishStatus: string = useMemo(() => {
    let text = ''
    switch (page.publishStatus) {
      case PagePublishStatusEnum.PublishedToLive:
        text = '已发布正式'
        break
      case PagePublishStatusEnum.PublishedToTest:
        text = '已发布测试'
        break
      default:
        break
    }
    return text
  }, [page])

  const isPublished = useMemo(
    () => page.publishStatus === PagePublishStatusEnum.Blank,
    [page],
  )

  const { mutateAsync: mutateDeletePage } = useMutation((id: string) => delPage(id))

  /**
   * edit
   */
  const [editVisible, setEditVisible] = useState(false)
  const handleSettings = useCallback(() => {
    setEditVisible(true)
  }, [])

  const handleEditOk = useCallback(() => {
    onRefresh?.()
    setEditVisible(false)
  }, [onRefresh])

  const handleEditCancel = useCallback(() => {
    setEditVisible(false)
  }, [])

  /**
   * copy
   */
  const [copyVisible, setCopyVisible] = useState(false)
  const handleCopy = useCallback(() => {
    setCopyVisible(true)
  }, [])
  const handleCopyOk = useCallback(() => {
    onRefresh?.()
    setCopyVisible(false)
  }, [onRefresh])

  const handleCopyCancel = useCallback(() => {
    setCopyVisible(false)
  }, [])

  /**
   * publish
   */
  const [publishVisible, setPublishVisible] = useState(false)
  const handlePublish = useCallback(() => {
    setPublishVisible(true)
  }, [])

  const handlePublishClose = useCallback(() => {
    setPublishVisible(false)
  }, [])

  /**
   * Card
   */
  const handleClick = useCallback(() => {
    const url = `/#${ROUTES.WORKBENCH}?id=${page.id}&projectId=${projectId}&instanceType=${INSTANCE_TYPE.PAGE}`;
    window.open(url, '_blank')
  }, [page, projectId])

  const handleDelete = useCallback(() => {
    Modal.confirm({
      icon: <InfoCircleFilled />,
      title: `确定要删除页面 "${page.name}" 吗?`,
      onOk: async () => {
        await mutateDeletePage(page.id)
        onRefresh?.()
      },
    })
  }, [mutateDeletePage, onRefresh, page])

  const handlePreview = useCallback(async () => {
    if (isPublished) {
      message.warning('当前页面是空页面！')
      return
    }
    const previewUrl = `/#${ROUTES.PREVIEW}?id=${page.id}`
    window.open(previewUrl, '_blank')
  }, [isPublished, page.id])

  const handleMoreMenuClick: MenuProps['onClick'] = useCallback(
    (menuInfo) => {
      const { key } = menuInfo
      switch (key) {
        case MoreMenuEnum.delete:
          handleDelete()
          break
        case MoreMenuEnum.settings:
          handleSettings()
          break
        case MoreMenuEnum.duplicate:
          handleCopy()
          break
        case MoreMenuEnum.preview:
          handlePreview()
          break
        case MoreMenuEnum.publish:
          handlePublish()
          break
        default:
          break
      }
    },
    [handleDelete, handleSettings, handleCopy, handlePreview, handlePublish],
  )

  const cardNode = (
    <Card className={styles.card} hoverable>
      <div className={styles.cardArea} onClick={handleClick}>
        <img className={styles.avatar} src={GET_DEFAULT_APP_AVATAR()} alt='' />
        <div className={styles.cardInfoArea}>
          <div className={styles.nameAndId}>
            <span className={styles.name} title={page.name}>名称: {page.name}</span>
            <span>ID:{page.id}</span>
          </div>
          <span className={`${styles.normalInfo} ${styles.description}`}>描述：{page.description || '-'}</span>
        </div>
      </div>
      <Dropdown
        key='ellipsis'
        trigger={['hover']}
        className={styles.dropdown}
        overlay={
          <Menu
            onClick={handleMoreMenuClick}
            items={[
              {
                label: (
                  <Space>
                    <CopyOutlined /> 复制
                  </Space>
                ),
                key: MoreMenuEnum.duplicate,
              },
              {
                label: (
                  <Space>
                    <SettingOutlined /> 设置
                  </Space>
                ),
                key: MoreMenuEnum.settings,
              },
              {
                label: (
                  <Space>
                    <DeleteOutlined /> 删除
                  </Space>
                ),
                key: MoreMenuEnum.delete,
              },
              {
                label: (
                  <Space>
                    <EyeOutlined /> 预览
                  </Space>
                ),
                key: MoreMenuEnum.preview,
              },
              {
                label: (
                  <Space>
                    <CloudUploadOutlined /> 发布
                  </Space>
                ),
                key: MoreMenuEnum.publish,
              },
            ]}
          />
        }
        placement='bottomRight'>
        <Button size='small' type='text' icon={<MoreOutlined />}></Button>
      </Dropdown>
    </Card>
  )

  return (
    <>
      {publishStatus ? <Badge.Ribbon text={publishStatus}>{cardNode}</Badge.Ribbon> : cardNode}
      <PageEdit
        visible={editVisible}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        title='Modify'
        data={page}
      />
      <PageCopy visible={copyVisible} onOk={handleCopyOk} onCancel={handleCopyCancel} data={page} />
      <PublishPane
        instanceId={page.id}
        projectId={projectId}
        instanceType={INSTANCE_TYPE.PAGE}
        visible={publishVisible}
        onClose={handlePublishClose}
        onPublish={() => onRefresh()}
        onRevert={() => onRefresh()}
      />
    </>
  )
}

export default PageCard
