export const supplementWithZero = (num: number, length: number): string => {
  const numStr = String(num)
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const str = numStr.length < length ? '0'.repeat(length - numStr.length) + num : num
  return String(str)
}

export const format = (timestr: number | string, formats = 'YY/MM/DD hh:mm'): string => {
  if (!timestr) {
    return ''
  }

  const time = new Date(Number(timestr))

  const year = time.getFullYear().toString().slice(-2)
  const month = supplementWithZero(time.getMonth() + 1, 2)
  const date = supplementWithZero(time.getDate(), 2)
  const hours = supplementWithZero(time.getHours(), 2)
  const minutes = supplementWithZero(time.getMinutes(), 2)
  const seconds = supplementWithZero(time.getSeconds(), 2)
  // const milliseconds = supplementWithZero(time.getMilliseconds(), 3)
  const res = formats
    .replace('YY', year)
    .replace('MM', month)
    .replace('DD', date)
    .replace('hh', hours)
    .replace('mm', minutes)
    .replace('ss', seconds)
  // .replace('ms', milliseconds)
  return res
}
